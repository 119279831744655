import { MutationUpdaterFn } from '@apollo/client'
import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import {
  AddTriggerToTrackMutation,
  Trigger,
  TriggerFragmentDoc,
  useAddTriggerToTrackMutation,
} from './types'

interface UseAddTriggerToTrackHook {
  addTriggerToTrack: () => Promise<Trigger | undefined>
}

export const useAddTriggerToTrack = (
  trackId: string,
): UseAddTriggerToTrackHook => {
  const { notifyError } = useNotifications()
 

  const [addTriggerToTrackMutation] = useAddTriggerToTrackMutation()

  const addTriggerToTrack = async () => {
    try {
      const update: MutationUpdaterFn<AddTriggerToTrackMutation> = (
        cache,
        mutationResult,
      ) => {
        const newTrigger = mutationResult.data?.addTriggerToTrack.trigger
        cache.modify({
          id: cache.identify({ __typename: 'Track', id: trackId }),
          fields: {
            triggers(existingTriggerRefs = [], { readField }) {
              const newTriggerRef = cache.writeFragment({
                data: newTrigger,
                fragment: TriggerFragmentDoc,
                fragmentName: 'Trigger',
              })

              // Quick safety check - if the new trigger is already
              // present in the cache, we don't need to add it again.
              if (
                newTrigger &&
                existingTriggerRefs.some(
                  (ref: Trigger) => readField('id', ref) === newTrigger.id,
                )
              ) {
                return existingTriggerRefs
              }
              return [...existingTriggerRefs, newTriggerRef]
            },
          },
        })
      }
      const { data } = await addTriggerToTrackMutation({
        variables: {
          input: {
            track_id: trackId,
          },
        },
        update,
      })
      return data?.addTriggerToTrack.trigger
    } catch (error) {
      notifyError({
        message: t('pathway_add_track_error'),
        error,
      })
      return undefined
    }
  }
  return {
    addTriggerToTrack,
  }
}
