import { useStytchMember, useStytchOrganization } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { environment } from '../environment'

interface CaptureDevSdk {
  setCustomContext: (context: Record<string, any>, replace?: boolean) => void
}

// @ts-expect-error see above
const Capture: CaptureDevSdk = window.Capture

/**
 * Identify the user in Capture Dev on login.
 */
export const useCaptureDev = (): void => {
  const { member } = useStytchMember()
  const { organization } = useStytchOrganization()
  useEffect(() => {
    if (!isNil(member) && !isNil(Capture)) {
      console.log('Setting Capture Dev context', { member, organization })
      try {
        Capture.setCustomContext({
          environment: environment.environment,
          user_email: member.email_address,
          organization_id: member.organization_id,
        })
      } catch (err) {
        console.error('Error identifying user from hook', err)
        // Nothing to do here, if this calls fails there is no need to panic or block the app usage
      }
    }
  }, [member])
  useEffect(() => {
    if (!isNil(Capture) && !isNil(organization)) {
      console.log('Setting Capture Dev context', { organization })
      try {
        Capture.setCustomContext({
          organization_slug: organization.organization_slug,
        })
      } catch (err) {
        console.error('Error identifying user from hook', err)
        // Nothing to do here, if this calls fails there is no need to panic or block the app usage
      }
    }
  }, [organization])
}
