// @ts-nocheck TODO: remove this comment and fix errors
import { environment } from '@awell/libs-web/environment'
import { Button } from '@awell/ui-kit/components/Button'
import { NotificationTypes } from '@awell/ui-kit/components/NotificationMessage'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useNotifications } from '../useNotifications'

/**
 * returns '' if release version not provided
 */
export const useMaintenanceStatus = () => {
  const [releaseVersion, setReleaseVersion] = useState('')
 
  const { notify } = useNotifications()

  useEffect(() => {
    try {
      // @ts-expect-error: Yes, firebase is loaded in global namespace. Stop complaining, typescript
      if (window.firebase) {
        // @ts-expect-error: Yes, firebase is loaded in global namespace. Stop complaining, typescript
        const db = firebase.default.firestore()
        const unsubscribe = db
          .collection('releases')
          .where('app', '==', 'pathway_studio')
          .where('status', '==', 'done')
          .orderBy('date', 'desc')
          .limit(1)
          .onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
              const { version } = doc.data()
              setReleaseVersion(version)
            })
          })
        return unsubscribe
      }
    } catch (err) {
      // Nothing to do here, if there is an issue loading the firebase SDK it should not block the app
    }
    return undefined
  }, [])

  useEffect(() => {
    if (releaseVersion && releaseVersion !== environment.version) {
      notify({
        message: t('notify_new_version_available_message'),
        notifyType: NotificationTypes.informative,
        persist: true,
        content: t('notify_new_version_available_content'),
        actions: (
          <Button
            variant='text'
            onClick={() => {
              window.location.reload()
            }}
          >
            {t('refresh_page')}
          </Button>
        ),
      })
    }
  }, [releaseVersion])

  return { releaseVersion }
}
