const hostedpages = {
  add_link_modal_subtitle:
    'Add another shareable link for a patient or care team member to complete activities with.',
  add_link_modal_title: 'Creating another shareable link',
  configure_link_modal_description: 'Customize your sharing link by changing the slug at the end of the URL.',
  configure_link_modal_title: 'Configure your sharing link',
  copied: 'Copied!',
  copy_link: 'Copy link',
  create_another_link: 'Create another link',
  create_link_modal_subtitle:
    "Ready to let your patients or care team members start completing tasks with a click? Simply click the button below and we'll create a link that you can share with them in seconds!",
  create_link_modal_title: 'Creating your shareable link',
  create_link_subtitle:
    "Ready to let your patients see their hosted pages? Simply click the button below and we'll create a link that you can share with them in seconds!",
  create_link_title: 'Create a shareable link',
  customize_link: 'Customize link',
  download_qr_code: 'Download QR code',
  enable_link: 'Enable link',
  link_options: 'Link options',
  modal_button_title: 'Create shareable link',
  modal_cancel_url_label: 'Cancel URL',
  modal_language_subtitle: 'Set the language in which stakeholders view this hosted page.',
  modal_language_title: "Set your hosted page's language",
  modal_slug_description: 'Your default link still works. ',
  show_default_link: 'Show default link',
  modal_slug_label: 'Start session URL',
  modal_stakeholder_subtitle: 'Set the stakeholder that will kickstart this care flow.',
  modal_stakeholder_title: "Set this hosted page's stakeholder",
  modal_success_url_label: 'Success URL',
  offline: 'Offline',
  online: 'Online',
  pre_assigned_stakeholders_link_subtitle:
    'A hosted page is a way for stakeholders assigned to one or more actions to complete them. In the Build view, please assign stakeholders to actions first.',
  pre_assigned_stakeholders_link_title: 'Ready to share? Assign actions to stakeholders first!',
  pre_live_link_subtitle:
    "Looks like you're almost ready to share your care flow! In the Versions tab above, please set any care flow as Live.",
  pre_live_link_title: 'Ready to share? Set a care flow as live first!',
  pre_publish_link_subtitle:
    "Looks like you're almost ready to share your care flow! Just hit 'Publish and set live' and you'll be set to share your hosted page.",
  pre_publish_link_title: 'Ready to share? Publish your care flow first!',
  sharing_subtitle: 'Your care flow is now published and ready to be shared.',
  sharing_title: 'Sharing',
  sharing_not_published_subtitle: 'Kickstart your care flow by sharing your hosted page.',
  slug_error: 'This slug is already in use. Please choose a unique slug.',
  stop_sharing_link: 'Stop sharing link',
  unknown_stakeholder: 'Unknown stakeholder',
  custom_domain: 'Custom domain',
  branding: {
    title: 'Hosted pages style',
    color_delete_button_label: 'Remove color',
    color_delete_modal_detail:
        'The care flow will fall back on the color defined at the tenant level.',
    subtitle: 'Customize the look and feel of Hosted Pages',
    logo_label: 'Logo',
    logo_description: 'The full-sized version of your logo.',
    accent_color_label: 'Accent color',
    accent_color_description:
        'A color that identifies your brand. We will use it to create a theme that fits with your brand.',
    preview_label: 'Live Preview',
    preview_description:
        'This is a preview of how Hosted Pages will look with your branding. This branding can be overridden at the care flow level. Changes are autosaved.',
    title_label: 'Page title',
    title_description:
        'This is the page title users will see when completing activities in this care flow with Hosted Pages.',
    auto_progress_label: 'Auto progress in conversational display mode',
    auto_progress_description:
        'When enabled, Hosted Pages will automatically progress to the next question when a user selects an answer for a single-select or yes/no question. Auto progress is only applicable in the conversational display mode.',
    autosave_label: 'Form answers autosave',
    autosave_description:
        "When enabled, Hosted Pages will automatically and temporarily save form answers to the user's browser. Within the same or subsequent sessions, questions already answered will be restored. While these saved answers do not expire, they are cleared on form completion or when the user clears their browser cache. Saved answers are also activity-specific, meaning we can track answers for multiple forms at once, or when reusing the same form later in a care flow.",
    custom_theme_label: 'Custom theme',
    custom_theme_description:
        'Apply custom theming to the Hosted Pages. Only available for Awell employees.',
    custom_theme_link: 'Learn more about custom theming.',
    accent_color_error:
      'Something went wrong while updating the accent color in branding settings.',
    logo_url_error: 'Something went wrong while updating the Logo image in branding settings.',
    hosted_page_title_error:
      'Something went wrong while updating the page title in branding settings.',
    hosted_page_auto_progress_error:
      'Something went wrong while updating the auto progress in hosted pages settings.',
    custom_theme_error: 'Something went wrong while setting custom theme in branding settings.',
  }
}

export default hostedpages
