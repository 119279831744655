import { useNotifications as useNotificationsWeb, NotificationsProvider } from '@awell/libs-web/hooks/useNotifications'

import { Button } from '@awell-health/design-system'
import React from 'react'
import { t } from 'i18next'
import { CrashReporter } from '../../crash-reporter'
import { usePathwayContext } from '../usePathwayContext'
import { useUser } from '../useUser'
import {
  type NotifyErrorOptions,
  type Notify,
  type NotifyError,
} from '@awell/libs-web/src/hooks/useNotifications/types'

interface Notifications {
  notify: Notify
  notifyError: NotifyError
  notifyDelete: Notify
  notifySuccess: Notify
}

export const useNotifications: () => Notifications = () => {
  const { notify, notifyError: notifyErrorWeb, notifyInformative, notifyDelete, notifySuccess } = useNotificationsWeb()
  const { user } = useUser()
  const { pathwayId, pathwayCaseId } = usePathwayContext()
 

  const notifyError = ({ error, message }: NotifyErrorOptions): void => {
    if (error !== undefined && error instanceof Error) {
      const ErrorActions = (): React.ReactElement => {
        const handleReport = (): void => {
          notifyInformative({
            message: t('error_reported'),
          })
          
          CrashReporter.report(error, {
            user: {
              id: user?.id,
              email: user?.email,
            },
            team: {
              id: user?.team?.id,
              name: user?.team?.name,
            },
            tenant: user?.tenant,
            pathwayId,
            pathwayCaseId,
          })
        }

        return (
          <Button variant="secondary" onClick={handleReport} data-heap="report-error">
            {t('report_error')}
          </Button>
        )
      }

      notifyErrorWeb({
        message,
        error,
        persist: true,
        actions: <ErrorActions />,
        closeOnClick: true,
      })

      try {
        // @ts-expect-error Heap SDK is not typed
        window.heap.track('StudioError', {
          tenant: user?.tenant,
          careflow_id: pathwayId,
          error_name: error.name,
          error_message: error.message,
        })
      } catch (err) {
        // Nothing to do here
      }
    }
  }

  return { notifyError, notify, notifyDelete, notifySuccess }
}

export { NotificationsProvider }
