import { t } from 'i18next'
import { useGetTracksQuery, Track } from './types'
import { useNotifications } from '../useNotifications'

interface UsePathwayTracksHook {
  tracks: Array<Track>
  loading: boolean
}

export const usePathwayTracks = (pathwayId: string): UsePathwayTracksHook => {
  const { notifyError } = useNotifications()
 
  const { data, loading, error } = useGetTracksQuery({
    variables: {
      pathwayId,
    },
  })

  if (error) {
    notifyError({
      message: t('pathway_fetch_error'),
      error,
    })
    return { loading: false, tracks: [] }
  }
  if (loading) {
    return { loading: true, tracks: [] }
  }

  const tracks = data?.pathway.pathway.tracks ?? []

  return {
    loading: false,
    tracks,
  }
}
