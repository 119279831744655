const translation = {
  '404_page_button': 'Go to homepage',
  '404_page_content':
    'The page you are looking for might have been removed, \nhad its name changed or is temporarily unavailable',
  '404_page_title': '404 - Page not found',
  action_other: 'actions',
  action_title: 'Action title',
  action_one: 'action',
  added_actions: 'Added actions',
  activated_on: 'Activated on',
  activation_date: 'Activation date',
  activity_one: 'activity',
  activity_activation_time: 'Activated {{ time }}',
  activity_other: 'activities',
  activity_type: 'Activity type',
  activity_feed_search_placeholder: 'Search the activity feed',
  activity_feed_start_text: "Hi, I'm Ava. I will guide you through the care flow. Click below to get started.",
  activity_sent_to: 'This {{ activity }} was sent to {{ indirect_object }}',
  add_action: 'Add action',
  specify_action: 'Specify action',
  add_condition: 'Add condition',
  add_custom_headers_to_request: 'Add custom headers to the HTTP {{ method }} request',
  add_data_point: 'Add data point',
  add_delay: 'Use a delay',
  add_empty_step: 'Add empty step',
  add_event: 'Add another event',
  add_header: 'Add header',
  add_new_option: 'Add new option',
  add_to_library: 'Add to Library',
  add_range: 'Add range',
  add_sticky_note: 'Add sticky note',
  add_selection_limits: 'Add selection limits',
  min_selectable: 'Minimum selectable',
  max_selectable: 'Maximum selectable',
  add_track: 'Add track',
  add_track_button_disabled: 'Tracks cannot be added at this time',
  add_track_heading: 'Add a track to this care flow',
  add_track_label: 'Select track to add',
  add_track_delay_warning:
    'Please be aware that you can only activate one track in this flow per minute. Adding multiple tracks at a time will queue them up for activation with a delay.',
  add_track_no_options: 'No ad hoc tracks available',
  add_track_track_started: 'Track added successfully',
  add_track_track_not_started: 'Track not added',
  get_ad_hoc_tracks_error: 'Something went wrong while fetching ad hoc tracks',
  add_transformation: 'Add transformation',
  add_transition: 'Add transition',
  add_trigger: 'Add trigger',
  add_label: 'Add a label',
  advanced: 'Advanced',
  ahp_link_save_error: 'Something went wrong while creating or updating the link',
  min: 'Min',
  max: 'Max',
  amount: 'Amount',
  ad_hoc: 'When track is manually triggered',
  ad_hoc_description: 'Tracks that are manually activated by a user or API call',
  add: 'Add {{ type }}',
  after_delay_of: 'After a delay of',
  never: 'Never',
  after: 'after',
  and: 'and',
  answer_type: 'answer type',
  answer: 'answer',
  api: 'API',
  api_call: 'API call',
  api_call_body_edit: 'Edit request body',
  api_call_data_point_create: 'Create API Call data point',
  api_call_endpoint_label: 'Endpoint',
  api_call_endpoint_error_http: 'Constant uses http protocol, only https is supported',
  api_call_enter_object_path_label: 'JSON path',
  api_call_enter_object_path: 'Enter JSON path',
  api_call_method_label: 'HTTP method',
  api_call_panel_title: 'API call',
  api_call_mock_response_label: 'Mock API response',
  api_mock_response_info:
    'You need to manually enter API response to complete this activity. You can use this dummy data to experiment with different combinations of your actual API responses. Actual API calls are not made in preview.',
  api_call_mock_response_button: 'Call Mock API',
  api_call_other: 'API calls',
  api_call_retry_all: 'Retry all API calls',
  api_call_request_label: 'Request',
  api_call_request_body_label: 'Request body',
  api_call_response_body_label: 'Body',
  api_call_response_status_label: 'Status',
  api_call_responses_label: 'Responses',
  api_call_status: 'API call status',
  api_call_test_button: 'Test request',
  api_call_test_modal_title: 'Test your API call action',
  api_call_test_send: 'Send',
  api_call_test_resend: 'Re-send',
  api_call_test_inputs_saved: 'Variable values successfully saved. You can now send the request.',
  api_call_one: 'API call (REST)',
  how_does_it_work: 'How does it work?',
  api_call_graphql: 'API call (GraphQL)',
  api_call_search_placeholder: 'Search by API call name',
  api_call_store_in: 'Store in',
  api_call_retry_all_failed_success: 'API calls successfully retried',
  api_call_retry_all_failed_error: 'Something went wrong while retrying failed API calls',
  api_call_retry_success: 'API call successfully retried',
  api_call_retry_error: 'Something went wrong while retrying API call',
  api_call_retry_enabled_label: 'Enable automated retries',
  api_call_retry_enabled_explanation:
    'Whether to automatically retry failed API calls. The retries use an exponential backoff algorithm, which you can override below',
  api_call_retry_initial_delay_ms_label: 'Initial delay in ms',
  api_call_retry_initial_delay_ms_explanation:
    'Set the time (in milliseconds) to wait before the first retry attempt. This is the delay applied after the initial call failure. Organisation-level setting: ',
  api_call_retry_max_delay_ms_label: 'Max delay in ms',
  api_call_retry_max_delay_ms_explanation:
    'Specify the maximum time (in milliseconds) to wait between consecutive retry attempts. The delay gradually increases with each attempt but will not exceed this value. Organisation-level setting: ',
  api_call_retry_max_attempts_label: 'Maximum amount of attempts',
  api_call_retry_max_attempts_explanation:
    'Enter the maximum number of retry attempts allowed when the API call fails. After reaching this limit, the API call will be resolved as failed. Organisation-level setting: ',
  api_call_skipped: 'Skipped',
  api_call_inprogress: 'In progress',
  api_call_failed: 'Failed',
  api_call_permanentlyfailed: 'Permanently failed',
  api_call_pending: 'Pending',
  api_call_success: 'Success',
  api_call_test_modal_input_info_tip:
    'Your API call action is dependent on the following variables. Set a value for them to execute your API call.',
  api_call_test_no_inputs_required: 'No variables are used in this API call, please click Send below',
  api_call_test_error_notification: 'Something went wrong while testing the API call',
  api_call_test_input_for_request: 'Input for request',
  api_call_test_save_inputs: 'Save inputs',
  archived: 'Archived',
  at: 'at',
  attach: 'Attach',
  attach_file: 'Attach file',
  attach_video: 'Attach video',
  attach_link: 'Attach link',
  attachment_preview_title: 'Attachment preview',
  attachment_preview_link: 'Click here to see the link',
  attachment_preview_document: 'Click here to see the document',
  automatically_generated_value_description: 'This value is generated automatically and cannot be changed',
  awell_sends_notifications: 'Awell sends notifications',
  awell_sends_notifications_description:
    'When enabled, Awell will send notifications via email for new & pending activities (reminders) to the apprioriate stakeholder. Disable this setting if you are taking care of sending notifications (eg: via Webhook triggers sent by our system to yours).',
  back: 'Back',
  back_to_last: 'Back to last page',
  back_to: 'Back to {{ destination }}',
  before: 'before',
  baseline_data_point_other: 'Baseline data points',
  baseline_data_points_settings_subtitle: 'Define data points that are expected when starting the care flow',
  baseline_info: 'baseline info',
  baseline_info_tip:
    'This care flow requires baseline information to be provided on start, please fill in the following:',
  basic: 'Basic',
  boolean: 'Yes / No',
  build: 'Build',
  by: 'by',
  by_awell: 'By Awell on',
  calculation: 'calculation',
  branding: 'Branding',
  create_link: 'Create Link',
  activity_link: 'Activity Page',
  hosted_pages: 'Hosted pages',
  calculation_disclaimer:
    'Disclaimer: These scores are not to be used as a sole basis for clinical decision making. These scores are intended for review by medically trained professionals only.',
  calculation_documentation_label: 'Intended use and other important information: ',
  calculation_results: 'Results',
  calculation_results_error: 'Something went wrong with the calculation',
  cancel: 'Cancel',
  called_at: 'Called at',
  care_flow_one: 'Care flow',
  care_flow_other: 'Care flows',
  case_one: 'Case {{ index }}',
  case_other: 'Cases',
  category: 'category',
  change_calculation: 'Change calculation',
  change_form: 'Change form',
  change_value_type_warning:
    'Changing the value type will keep your options and their labels, but reset all option values. Please confirm to proceed.',
  change_value_type_warning_explanation:
    'Please note that changing the value type of a data point generated by this question, while it is actively in use, will require you to update all display conditions, variables, and transitions associated with this data point. Keep in mind that this change is irreversible.',
  change_value_type_heading: 'Confirm change',
  change_language: 'Change language',
  checklist_load_error_notification: 'Something went wrong while loading the checklist.',
  checklist_submit_error_notification: 'Something went wrong while submitting the checklist response.',
  checklist_success_notification: 'Checklist completed successfully.',
  checklist: 'checklist',
  clinical_note: 'Clinical note',
  clinical_note_narratives_description: 'A clinical note can contain one or more narratives.',
  clinical_note_create_new_narrative_modal_title: 'Add a new narrative',
  clinical_note_edit_narrative_modal_title: 'Edit narrative',
  clinical_note_edit_body: 'Edit clinical note narrative content',
  clinical_note_create_new_narrative: 'Create new narrative',
  clinical_note_create_narrative_button: 'Create narrative',
  clinical_note_key_description: 'A unique identifier for your narrative. Note that this cannot be changed afterwards.',
  clinical_note_title_description: 'The title of your narrative.',
  clinical_note_context_description:
    'Add contextual metadata to describe your clinical note like a title or description.',
  clinical_note_tab_context: 'Context',
  clinical_note_tab_narratives: 'Narratives',
  choose_action: 'Choose an action',
  choose_destination: 'Choose a destination',
  choose_notification_provider_title: 'Choose your notification provider',
  choose_notification_provider_description:
    'You can choose a notification provider of your own (e.g. Sendgrid) or you can choose the default provider i.e Awell (Awell sends notifications using Mailgun).',
  choose_starting_option: 'Choose a starting option',
  close: 'Close',
  collapse_all: 'Collapse all',
  collection_contexts: 'Where is this data point collected',
  collection_date: 'collection date',
  complete_next_activity: 'Complete next activity',
  completed_on: 'Completed on',
  commit: 'Commit',
  condition_one: 'condition',
  condition_other: 'Conditions',
  condition_label: '{{ operator }} {{ operand }}',
  condition_operator_CONTAINS: 'Contains',
  condition_operator_DOES_NOT_CONTAIN: 'Does not contain',
  condition_operator_IS_ANY_OF: 'Is any of',
  condition_operator_IS_EMPTY: 'Has no value',
  condition_operator_IS_EQUAL_TO: 'Equal to (=)',
  condition_operator_IS_GREATER_THAN: 'Higher than (>)',
  condition_operator_IS_LESS_THAN: 'Lower than (<)',
  condition_operator_IS_GREATER_THAN_OR_EQUAL_TO: 'Higher than or equal to (≥)',
  condition_operator_IS_LESS_THAN_OR_EQUAL_TO: 'Lower than or equal to (≤)',
  condition_operator_IS_NONE_OF: 'Is none of',
  condition_operator_IS_NOT_EMPTY: 'Has any value',
  condition_operator_IS_NOT_EQUAL_TO: 'Not equal to (≠)',
  condition_operator_IS_IN_RANGE: 'Is between two values',
  condition_operator_IS_NOT_TRUE: 'Is no',
  condition_operator_IS_TRUE: 'Is yes',
  condition_operator_IS_TODAY: 'Is today',
  condition_operator_IS_MORE_THAN_X_DAYS_AGO: 'Is more than X days ago',
  condition_operator_IS_LESS_THAN_X_DAYS_AGO: 'Is less than X days ago',
  condition_operator_date_IS_LESS_THAN_X_DAYS_AGO: 'Is less than {{ operand }} days ago',
  condition_operator_date_IS_MORE_THAN_X_DAYS_AGO: 'Is more than {{ operand }} days ago',
  condition_operator_HAS_FILE_UPLOADED: 'Has file uploaded',
  condition_operator_HAS_NO_FILE_UPLOADED: 'Has no file uploaded',
  configure_transition: 'Configure transition',
  configure_trigger: 'Configure trigger',
  configure_pathway_extension_error: 'Something went wrong while configuring a new extension',
  configuration_extension_removal_error: 'Something went wrong while removing the extension',
  constant_one: 'Constant',
  CONTAINS: 'contains',
  content: 'content',
  continue: 'continue',
  copy_to_clipboard: 'Copy to clipboard',
  copy_to_clipboard_success: 'Copied',
  copy_to_clipboard_error: 'Copy to clipboard failed',
  copy_url:
    'Sharing link copied! This lets others watch and interact with this case simulation without being able to change the care flows.\nPlease note that any simulation or reset by others will impact the current state of this case.',
  count_1: 'first',
  count_2: 'second',
  count_3: 'third',
  count_4: 'fourth',
  count_5: 'fifth',
  count_6: 'sixth',
  count_7: 'seventh',
  count_8: 'eighth',
  count_9: 'ninth',
  create_account: 'Create your account',
  create_baseline_datapoint: 'Create baseline data point',
  create_new_careflow: 'Create care flow',
  create_new_case: 'Create case',
  create_pathway_title: 'Create a new care flow',
  create_transition_error: 'Something went wrong while creating the transition',
  create_transition_duplicate_error:
    'A transition to this step already exists. Please remove the existing transition first',
  create_webhook: 'Create webhook',
  create_webhook_subtitle: 'Your app can be notified of real time events happening in the care flow',
  create: 'Create',
  created: 'Created',
  current: 'Current',
  cursor: 'Cursor',
  cycle_one: 'Cycle',
  cycle_other: 'Cycles',
  cycles_detected_explanation:
    'There is a cycle in your care flow, which is currently not supported by the studio. In order to preview this care flow you will need to review the steps below and get rid of any transition that goes back to a previous step and therefore creates a cycle.',
  general: 'General',
  dashboard: 'Dashboard',
  dashboard_webhooks_description: 'Monitor clinical and technical data of the care flow',
  baseline_data_point_optional: 'Optional',
  DATA_SOURCE: 'data source',
  data_variable_load_error: 'Something went wrong while loading variable data',
  data_variable_name_already_exists: 'This variable name is already in use. Please choose a unique name',
  data_variable_name_required: 'Variable name is required',
  delegated_to: 'Delegated to',
  date_and_time: 'Date and time',
  date: 'date',
  DAY_other: 'days',
  DAY_one: 'day',
  decimal_places_count: 'Number of decimal places',
  default: 'Default',
  delete_care_flow: 'Delete care flow',
  delete_error: 'Something went wrong while trying to delete this {{ item }}. Please try again.',
  delete_modal_header: 'Are you sure you want to delete this {{ item }}?',
  delete_modal_header_default: 'Are you sure?',
  delete_modal_primary_button: 'Delete',
  delete_modal_secondary_button: 'Cancel',
  delete_track: 'Delete track',
  delete_transition_error: 'Something went wrong while deleting the transition',
  delete_sticky_note: 'Delete $t(sticky_note)',
  delete_sticky_note_error: 'Something went wrong while deleting the sticky note',
  delete_success: '{{ item }} successfully deleted',
  delete: 'Delete',
  delete_with_keyboard_shortcut: 'Delete - \n "ctrl / ⌘ + shift + backspace" ',
  description: 'Description',
  developer_action_other: 'Developer actions',
  diff_review_changes_title: 'Review changes to your careflow',
  diff_review_changes_description: 'Compare changes applied to this version to the previous version.',
  diff_previous_version: 'Previous version',
  diff_current_version: 'Current version',
  diff_inspector_format_description_1: 'Currently, not all changes can be viewed via our visual inspector.',
  diff_inspector_format_description_2: 'For a comprehensive review of all changes, please use our code inspector.',
  disabled: 'Disabled',
  display_name: 'display name',
  display_name_placeholder: 'Enter displayed name',
  documentation_load_error_notification: 'Something went wrong while loading the step documentation.',
  DOES_NOT_CONTAIN: 'does not contain',
  done: 'Done',
  done_complete_activity: '{{ stakeholder }} completed the actvity on {{ date }}.',
  done_complete_checklist: '{{ stakeholder }} completed the checklist on {{ date }}.',
  done_read_message: '{{ stakeholder }} read the message on {{ date }}.',
  done_submit_form: '{{ stakeholder }} submitted the form on {{ date }}.',
  download: 'Download',
  download_working_version_tooltip: 'Download the working version of this care flow as JSON',
  error_load_file_storage_configs: 'Something went wrong while loading the file storage configurations',
  duplicate: 'Duplicate',
  duplicate_careflow: {
    confirm_modal: {
      title: 'You\'re about to duplicate the care flow, "{{ item }}"',
      primary_button: 'Duplicate care flow',
      secondary_button: 'Cancel',
      body: 'It will create a copy of the existing care flow, including all steps, actions, and configurations. Do you want to proceed?',
      loading: "We're duplicating your care flow",
      loading_body:
        'Hold still. Your duplicated care flow will be ready shortly. Large care flows may take 60 seconds or longer...',
      success: 'Care flow duplicated successfully!',
      success_body: 'Click the button below to open your duplicated care flow.',
      error: "Uh oh! We couldn't duplicate your care flow.",
      error_body_too_large:
        'The care flow you wanted to duplicate is too large to be duplicated directly in the app. However, we can still assist you with this process. Please reach out to the Awell team, and we will duplicate the care flow for you.',
      error_body:
        'There was an issue attempting to duplicate your care flow. However, we can still assist you with this process. Please reach out to the Awell team, and we will duplicate the care flow for you.',
      close: 'Close',
      open_care_flow: 'Open care flow',
    },
  },
  duplicate_with_keyboard_shortcut: 'Duplicate - "ctrl / ⌘ + d"',
  draft: 'Draft',
  edit_form: 'Edit form',
  edit_message: 'Edit message',
  edit_pathway: 'Edit care flow',
  edit_transition: 'Edit transition',
  edit_variables: 'Edit variables',
  edit_webhook: 'Edit webhook',
  edit: 'Edit',
  element_one: 'Element',
  element_other: 'Elements',
  email: 'Email',
  emr_panel_title: 'EMR integration details',
  emr_integration: 'EMR Integration',
  emr_integration_config_metadata_guide:
    'Metadata is data that provides information or instructions about one or more aspects of the integration.',
  emr_integration_config_report_guide:
    'Report is data that provides information or instructions about one or more aspects of the integration.',
  emr_report_label: 'Report',
  emr_step_library_tooltip:
    'Any care flow specific activities (e.g. EMR integrations) will be removed from the step template when adding this step to the Step Library',
  enable: 'Enable',
  enabled: 'Enabled',
  enable_activity_to_see_element: 'You need to select Action in the Element filter to use this filter',
  enable_webhook: 'Enable webhook',
  end_date: 'End date',
  end_track: 'End track',
  endpoint: 'Endpoint',
  enter_some_text: 'Click Edit to start documenting this step',
  start_typing: 'Start typing...',
  input_error_mandatory: 'This field is required',
  input_error_invalid: 'The value provided is not valid, please check and try again',
  input_error_invalid_named: 'This {{ input }} is invalid',
  input_error_invalid_number_details: 'value should be a valid number. You entered a non-number value, which',
  error_other: 'Errors',
  error_add_baseline_datapoint: 'Something went wrong while adding baseline data point to care flow. {{ message }}',
  error_action_content_not_available: 'Content of this action is unavailable',
  error_description: "Error detail: '{{ message }}'",
  error_delete_webhook: 'Something went wrong while deleting webhook',
  error_message: 'Error message',
  generic_error_msg: 'Something went wrong',
  error_action_update: 'Something went wrong while updating the action',
  error_configure_step: 'Something went wrong while creating a step from template',
  error_clinical_note_narrative_add: 'Something went wrong while creating clinical note narrative',
  error_clinical_note_narrative_update: 'Something went wrong while updating clinical note narrative',
  error_clinical_note_narrative_remove: 'Something went wrong while deleting clinical note narrative',
  error_clinical_note_context: 'Something went wrong while updating clinical note context',
  error_empty_fields: 'Fields cannot be empty',
  error_load_action: 'Something went wrong while loading an action',
  error_load_actions: 'Something went wrong while fetching the step actions',
  error_load_api_call_other: 'Something went wrong while fetching api calls',
  error_load_custom_actions: 'Something went wrong while loading custom actions',
  error_load_data_catalog: 'Something went wrong while loading the data catalog',
  loading_data_catalog_json_error: 'Something went wrong while loading the data catalog',
  error_load_message: 'Something went wrong while loading the message',
  error_load_report: 'Something went wrong while loading the EMR details',
  error_load_step: 'Something went wrong while loading a step',
  error_load_webhook_one: 'Something went wrong while loading the webhook',
  error_load_webhook_other: 'Something went wrong while loading webhooks',
  error_load_pathway_validation_status: 'Something went wrong while loading validation results',
  error_load_extension_record: 'Something went wrong while loading the extension record',
  error_load_patient_stakeholder:
    'There was an issue loading the patient stakeholder configuration. Please try again later.',
  error_message_get_user: 'Something went wrong while fetching your details',
  error_data_point_update: 'Something went wrong while updating data point',
  error_message_invite_users: 'Something went wrong while inviting the users',
  error_non_existing_dynamic_variable_title: 'The variable with this id does not exist in this care flow',
  error_non_existing_dynamic_variable:
    'This might happen when message content containing variables is copy-pasted between care flows. \n To fix this error check your messages for invalid variables or contact our dev team.',
  error_page_button: 'Refresh page',
  error_page_content: "Something didn't quite work right. \nLet's see if reloading the page will fix it.",
  error_page_title: 'Oops!',
  error_extension_field_type_not_supported:
    'This extension field type is not supported, please check configuration of this extension',
  error_refresh_extension: 'Something went wrong while refreshing the extension',
  error_reported: 'Report successfully sent. Thanks for helping us build a great app!',
  error_rebuild_graph: 'Something went wrong while running validation',
  error_attachment_save: 'Unable to save the attachment',
  error_attachment_remove: 'Unable to remove the attachment',
  error_attachment_name: 'Unable to set the attachment name',
  error_attachment_url: 'Unable to set the attachment URL',
  error_attachments_reorder: 'Unable to reorder the attachments',
  error_message_body: 'Unable to set the message body',
  error_message_subject: 'Unable to set the message subject',
  error_publishing_pathway: 'Something went wrong while publishing the care flow',
  error_mark_pathway_release_as_live: 'Something went wrong while setting the care flow release as live',
  error_remove_baseline_data_point_from_pathway:
    'Something went wrong while removing baseline data point from care flow. {{ error }}',
  error_set_baseline_data_point_optional: 'Something went wrong while performing this action. {{ error }}',
  error_retry_webhook: 'Something went wrong while retrying webhook',
  error_retry_webhook_call: 'Something went wrong while retrying webhook',
  error_retry_all_webhook_calls: 'Something went wrong while retrying all webhook calls',
  error_retry_all_failed_webhook_calls: 'Something went wrong while retrying failed webhook calls',
  error_update_baseline_datapoint: 'Something went wrong while updating baseline data point. {{ message }}.',
  error_in_api_call: 'Invalid API call found in the care flow.',
  error_in_constant: "Invalid constant found in the care flow. Please check the '{{ title }}' constant.",
  error_in_dynamic_variable:
    "Invalid dynamic variable found in the care flow. Please check the '{{ title }}' variable.",
  error_in_calculation: 'Invalid calculation action found in the care flow.',
  error_in_clinical_note: 'Invalid clinical note action found in the care flow.',
  error_publishing: 'An error occurred while publishing the care flow. Please correct the issues listed below.',
  error_in_form: 'Invalid form action found in the care flow.',
  error_in_extension_action: 'Invalid extension action found in the care flow.',
  error_in_message: 'Invalid message action found in the care flow.',
  export: 'Export',
  expand_all: 'Expand all',
  extension_sent_datapoints_label: 'Sent',
  extension_received_datapoints_label: 'Received',
  send_values_to_extension: 'Send values to {{ title }}',
  receive_values_from_extension: 'Receive values from {{ title }}',
  extension_action: 'Extension - Action',
  extension_action_datapoints_explanation: 'Store data from the action to data points in your care flow',
  extension_webhook: 'Extension - Webhook',
  extension_no_webhooks: 'This extension does not expose any webhooks.',
  extension_no_datapoints: 'This action does not create any data points.',
  extension_no_fields_required: 'This action does not require any configuration',
  extension_action_field_static_value: 'Fixed value',
  extension_action_field_data_point: 'Data point',
  extension_refresh_complete: 'Refresh complete',
  extension_refresh_not_supported_actions:
    'The following extension actions are no longer supported by this extension, please replace:',
  extension_not_supported_action_track_step: ' action in track {{trackTitle}}, step {{stepTitle}}',
  extension_select_action_data_point: 'Choose data point to store',
  extension_select_action_data_point_first: 'First choose data point to store',
  extension_store_in: 'Store in',
  extension_data_point_create: 'Create extension data point ({{valueType}})',
  extension_no_settings_required: 'This extension does not require any configuration. Click Enable to continue',
  extension_create_data_points_btn: 'Create data points',
  extension_create_data_points_description:
    'This extension does not create any data points yet. Create data points to use the received values in the careflow.',
  extension_data_points_added: 'Successfully added the mapped data points to the careflow',
  extension_dynamic_variables_added: 'Successfully created new dynamic variables for the data points',
  extension_data_points_mapping_modal: {
    title: 'Map received data from {{extension}}',
    description:
      'This wizard automatically creates dynamic variables and data points for the data received from the extension. You can change the data point names or map the data to existing data points in the careflow.',
    btn_label: 'Save data points & variables',
  },
  fallback: 'Fallback',
  fallback_input_description: 'Configure a fallback that will be printed when there’s no data to display.',
  fallback_placeholder: 'If there’s nothing to display, can be left blank',
  false: 'false',
  fields: 'Fields',
  file: 'file',
  filter_activities: 'Filter activities',
  formbuilder: {
    telephone: {
      default_country: 'Default country',
      available_countries: 'Limit phone numbers to these countries',
      all_countries: 'All countries',
    },
    multi_select: {
      option_value_type_change_helper_text:
        'Use numbers when you need values for calculations. Text allow for elaborate and descriptive answer values.',
    },
  },
  generic_graphql_error_msg: 'Something went wrong while {{ action }} {{ resource }}',
  graphql_query: 'fetching',
  graphql_mutation: 'updating',
  general_practitioner: 'General practitioner',
  get_action_calculation_error_notification: 'Something went wrong while retrieving the action calculation.',
  get_calculation_result_error_notification: 'Something went wrong while retrieving the calculation results.',
  get_form_response_error_notification: 'Something went wrong while retrieving the form response.',
  get_pathway_data_points_error_notification: 'Something went wrong while loading the care flow data points',
  go_to_destination: 'Go to {{ destination }}',
  go_to_step: 'Go to step',
  go_to_track: 'Go to track',
  go_to: 'go to',
  group_by: 'group by',
  action_idle_time: {
    toggle: 'Enable action expiration',
    form_description:
      'After expiry, the care flow will skip the action. This ensures that the assignee no longer has to complete the form, allowing activities to resume.',
    message_description:
      'After expiry, the care flow will skip the action, ensuring the recipient no longer has to read the message.',
    clear_error: 'Something went wrong while clearing the action expiration time',
    set_error: 'Something went wrong while setting the action expiration time',
  },
  action_due_date: {
    main_toggle: 'Set due date',
    main_toggle_description:
      'Activity due date will be visible in the task management dashboard only. A delay is calculated from the activity start time or the reference data point which must be available at the time of activity start time.',
    secondary_toggle: 'Add a delay to the reference date',
    fixed_value_tab: 'After a delay of',
    reference_date_tab: 'Use reference date',
    clear_error: 'Something went wrong while removing the due date',
    set_error: 'Something went wrong while updating the due date',
    remove_error: 'Failed to remove due date',
  },
  pending_activity_one: '{{ count }} pending activity',
  pending_activity_other: '{{ count }} pending activities',
  pending_triggers_title: 'Pending triggers',
  pending_triggers_description:
    'Following tracks are scheduled to be activated based on certain events (not) taking place in the care flow.',
  no_pending_activities: 'No pending activities',
  no_data_points_found: 'No data points',
  no_triggers: 'No triggers',
  no_webhooks_found: 'No webhooks found',
  pending_activities_title: 'Pending activities',
  HAS_NO_VALUE: 'has no value',
  HAS_VALUE: 'has value',
  header_name: 'Header name',
  header_other: 'Headers',
  help: 'Help',
  highlight_activities_for: 'Highlight activities for',
  HOUR_other: 'hours',
  HOUR_one: 'hour',
  how_to_use_transitions: 'How to use transitions',
  http_not_supported_error_text:
    'Watch out! The value entered uses the http protocol, but Awell only supports https{{forPurpose}}',
  http_not_support_for_api_calls:
    ' for API calls. Please use https:// instead if you want to use this for an endpoint.',
  id: 'Id',
  if: 'If',
  immediately_after: 'Immediately after',
  immediately: 'Immediately',
  import_export_answers_button: 'Import/export answers',
  import_export_options_modal_title: 'Import and export options',
  import_export_options_modal_description:
    'Enter each answer option on a new line. You can specify values by using the following format: "{answerValue}={answerLabel}". If no values are provided or not all options have valid values, the system will create auto-incremented values for you. Note that we only support numerical answer values.',
  import_export_options_placeholder: '0=Option 1 \n1=Option 2 \n2=Option 3\n\nOR\n\nOption 1 \nOption 2 \nOption 3',
  import_export_data_error_title: 'Incorrect data',
  'import_export_data_error-not-a-number': 'Line {{ line }} - {{ element }} must be a number',
  'import_export_data_error-required': 'Line {{ line }} - {{ element }} is required',
  import_from_library: 'Import from library',
  import_from_json: 'Import from JSON',
  import_from_tmn_format: 'Import from Treat-Me-Now format',
  import_form_tmn_helper_text:
    'Use the Treat-Me-Now format to import questionnaire data into the form builder. The TMN questions will be automatically mapped to the corresponding question types in the form builder. ',
  import_from_json_validation_warning:
    'There are some issues with the shape of your JSON object you try to import. You can find the list of errors below.',
  import_questions_button: 'Question library',
  import_questions_modal_title: 'Question library',
  import_questions_modal_description: 'Import questions from our library of validated questionnaires or via JSON.',
  in_all_cases: 'In all cases',
  indirect_object: 'Indirect object',
  input_error_invalid_constant: 'Constant with this label does not exist',
  input_error_invalid_email: 'One of the provided emails is not valid. If using a Constant, please check its value.',
  input_error_invalid_email_answer: 'Provided email is invalid',
  insert: 'Insert',
  insert_image: 'Insert image',
  insert_link: 'Insert link',
  insert_video: 'Insert video',
  insert_hosted_pages_activity: 'Insert Hosted Pages',
  invalid_error_text: 'This answer is in an invalid format',
  invalid_url: 'Invalid URL',
  invalid_datapoint_mapping: 'Invalid data point mapping',
  is_hyperlink: 'Use as hyperlink',
  is_hyperlink_description:
    'When enabled, this variable will be displayed as a clickable link in messages. Use this for variables containing URLs.',
  IS_ANY_OF: 'is one of',
  IS_EMPTY: 'is empty',
  IS_EQUAL_TO: 'is equal to',
  IS_GREATER_THAN: 'is greater than',
  IS_LESS_THAN: 'is less than',
  IS_NONE_OF: 'is none of',
  IS_NOT_EMPTY: 'is not empty',
  IS_NOT_EQUAL_TO: 'is not equal to',
  IS_NOT_TRUE: 'is no',
  IS_TRUE: 'is yes',
  json: 'Json',
  json_path: 'Json path',
  json_path_description:
    'The path to the value in the JSON response. See https://github.com/json-path/JsonPath for more details',
  key: 'key',
  label: 'label',
  label_checklist_stakeholder: 'Assignee',
  label_form_stakeholder: 'Assignee',
  logs_description: 'Monitor all changes that happen to your careflow in one place',
  label_form_id: 'Select form',
  label_calculation_id: 'Select calculation',
  label_message_location: 'Where is this message meant to be read?',
  label_recipients: 'Recipient(s)',
  labels_list_label_text_placeholder: 'Click to edit label',
  language: 'Language',
  last_simulation_date: 'Last preview:',
  last_modified_when_by: 'Last modified on {{ when }} by {{ author }}.',
  learn_more: 'Learn more',
  link: 'link',
  link_awell_help: 'https://help.awellhealth.com',
  live: 'Live',
  loading: 'Loading ...',
  logged_in_user_email: 'Logged in as {{ email }}',
  login: 'Login',
  logout: 'Logout',
  logout_and_open_invite_again:
    'We noticed you already have an Awell account with {{ userEmail }} and that you are logged in. To accept the invite for {{ inviteEmail }} your first need to log out.',
  long_text: 'Long text',
  logs: 'Logs',
  mandatory_field_error_text: 'This field is required',
  manage: 'Manage',
  member_one: 'Just you',
  member_other: '{{count}} members',
  menu_awell_help: 'Help Center',
  message_content_missing: 'No content provided.',
  message_details: 'Message details',
  message_error_notification:
    'The message could not be marked as read. Please reopen the message details to try again.',
  message_load_error_notification: 'Something went wrong while loading the message.',
  message_recipients_missing: 'No recipients specified yet.',
  message_subject_missing: 'No message subject provided.',
  message: 'message',
  metadata: 'metadata',
  metadata_unsaved_changes: 'You have unsaved changes',
  MINUTE_other: 'minutes',
  MINUTE_one: 'minute',
  mobile_not_available: 'This view is not optimised for mobile. Use the button below or menu options above to go back.',
  modified: 'Modified',
  MONTH_other: 'months',
  MONTH_one: 'month',
  multiple_select: 'Multiple select',
  name: 'Name',
  navigation_graph_rebuild: 'Refresh workflow',
  navigation_graph: 'Workflow',
  navigation_graph_validation_description: [
    'Due to your latest modifications, we have trouble defining the correct order of the steps in your care flow.',
    'Please help us and click the button below to refresh the care flow in our system.',
  ],
  allowed_dates: 'Allowed dates',
  all_dates: 'All dates',
  future_dates: 'Future dates',
  past_dates: 'Past dates',
  include_date_of_response: 'Include date of response',
  new_action: 'New {{ type }} action',
  new_checklist_item: 'New checklist item',
  new_step: 'New step',
  untitled_step: 'Untitled step',
  new_sticky_note: 'New $t(sticky_note)',
  next_activity: 'Next activity',
  no_activities_yet:
    'There are no activities for this role yet. Either select $t(next_activity) below to continue or wait for the preview driver to do so.',
  no_details: 'No further details available right now.',
  no_label: 'No label',
  no_pathway_stakeholders: 'No stakeholders found in care flow',
  no_search_results: 'No search results',
  no_value_provided: 'Value not set',
  no_options: 'The {{ name }} option list is empty',
  no: 'no',
  not_completed: 'Not completed',
  not_collected: 'Not collected',
  not_completed_within: 'Not completed within',
  not_collected_within: 'Not collected within',
  not_configured: 'Not configured',
  not_configured_description: 'Tracks that have no activation trigger',
  not_selected: 'Not selected',
  notify_new_version_available_content:
    'In order to keep using the app we ask you to refresh the page to get the latest update. All your changes have been saved.',
  notify_new_version_available_message: 'A new version of the Awell Studio is available!',
  notify_step_template_added_error: 'Something went wrong while adding this step to the library',
  notify_step_template_added_success: 'Step successfully added to library!',
  notification_other: 'Notifications',
  notification_provider: 'Notification provider',
  notification_provider_awell: 'Awell',
  notification_provider_sendgrid: 'Sendgrid',
  notification_provider_none: 'No notification provider',
  notification_subtitle: 'Configure how and when stakeholders should be notified for new and pending activities.',
  number: 'number',
  numbers_array: 'Numbers array',
  not_published: 'Unpublished',
  no_unpublished_changes: 'No unpublished changes',
  object: 'Object',
  open_calculations_explorer: 'Open calculations explorer',
  operand: 'Operand',
  operator: 'Operator',
  optional: 'Optional',
  on: 'on',
  or: 'or',
  orphan: 'orphan',
  other_one: 'other',
  other_other: 'others',
  overview: 'Overview',
  patient: 'patient',
  patient_data: {
    patient_id: 'Patient.ID',
    identifier: 'Patient.Identifier',
    patient_code: 'Patient.Patient Code',
    first_name: 'Patient.First Name',
    last_name: 'Patient.Last Name',
    national_registry_number: 'Patient.National Registry Number',
    birth_date: 'Patient.Birth Date',
    email: 'Patient.Email',
    mobile_phone: 'Patient.Mobile Phone Number',
    phone: 'Patient.Phone Number',
    sex: 'Patient.Sex',
    preferred_language: 'Patient.Preferred Language',
    patient_timezone: 'Patient.Timezone',
    address: {
      street: 'Patient.Address.Street',
      city: 'Patient.Address.City',
      state: 'Patient.Address.State',
      zip: 'Patient.Address.Zip/Postcode',
      country: 'Patient.Address.Country',
    },
  },
  pathway_activities: 'Care flow activities',
  pathway_creation: 'No care flow found',
  pathway_fetch_error: 'Something went wrong while fetching your care flow',
  pathway_case: 'Care flow case',
  pathway_cases: 'Care flow cases',
  pathway_started: 'When care flow starts',
  learn_more_about_source_control_link:
    'https://developers.awellhealth.com/awell-studio/docs/source-control/introduction',
  source_control_link_title: 'Learn more about source control',
  hosted_pages_link_title: 'Learn more about hosted pages sharing link',
  pathway_started_description: 'Tracks that are activated when the care flow is started',
  pathway_stakeholder_patient_explanation: "The patient's contact details are configured in the patient profile.",
  pathway_stakeholder_physician_explanation: "The physician's contact details are configured in the patient profile.",
  pathway_stakeholder_gp_explanation:
    "The general practitioner's contact details are configured in the patient profile.",
  pathway_stakeholder_language_explanation: 'Set the language for email notifications.',
  pathway_table_column_created_by: 'Created by',
  pathway_table_column_modified_by: 'Modified by',
  pathway_table_column_modified_on: 'Last modified',
  pathway_table_column_pathway_name: 'Care flow name',
  pathway_table_column_version: 'Version',
  pathway_table_error_fetching: 'Something went wrong while fetching your care flows',
  pathway_table_error_page_button: 'Refresh page',
  pathway_table_error_page_content:
    'You seem to have found an error on this page\nNot to worry - you can always try to refresh it',
  pathway_title: 'Care flow title',
  careflow_tracks: 'Care flow tracks',
  careflow_tracks_description:
    'Use tracks to organize your care flow. Set triggers based on care flow events to start tracks when needed.',
  pathway: 'Care flow',
  pathways: 'Care flows',
  pathway_add_track_error: 'Something went wrong while creating new track',
  pathway_remove_track_error: 'Something went wrong while removing the track',
  pathway_rename_track_error: 'Something went wrong while renaming the track',
  pathway_stakeholder_email: 'care flow stakeholder email',
  pathway_stakeholder_email_explanation:
    'The "{{ stakeholder }}" stakeholder is not yet supported. Set an email address that we can use to send notifications to.',
  pathway_stakeholder_email_placeholder: 'email address for {{ stakeholder }}',
  patient_profile: "Patient's profile",
  patient_identifier: "Patient's identifier",
  pause_case: 'Pause case',
  pagination_currently_shown_items_info:
    ' Showing {{ firstShownQuestion }} - {{ lastShownQuestion }} of {{ totalCount }} questions',
  pending_activities_none: 'There are no pending activities',
  pending_activities_order_label: 'Sorted by oldest activity first',
  pending_activities: 'Complete the pending activities to resume the case',
  phone: 'Phone',
  physician: 'physician',
  pii: 'PII',
  pii_description: 'Mark this data point as personally identifiable information',
  placeholder_email: 'henrietta@awellhealth.com',
  placeholder_password: 'Password 8+ characters',
  placeholder_paste_url: 'Paste your link here',
  please_check_transition: 'Please check transition configuration',
  please_complete_checklist: 'Please complete this checklist',
  please_complete_form: 'Please complete this form',
  please_complete_message: 'Please read this message',
  please_complete_this: 'Please complete this activity',
  please_wait: 'Please wait...',
  extension_one: 'Extension',
  extension_other: 'Extensions',
  prev: 'Prev',
  publish: 'Publish',
  publish_only: 'Publish only',
  publish_and_set_live: 'Publish and set live',
  published: 'Published',
  published_when_by: 'Published on {{ when }} by {{ author }}.',
  publish_pathway: 'Publish care flow',
  publishing_title: 'Publish a new version',
  publishing_info_when_source_control_enabled:
    'All changes in the care-flow will be committed to the branch "{{ branch }}" in your Git repository.',
  publishing_title_on_error: 'Failed to publish a new version',
  publishing_comments_title: 'Add some context on why you’re publishing a new version or on what changes you did.',
  publishing_comments_placeholder: 'Type your comments...',
  publishing_confirmation_title: 'Your care flow was updated to version {{ version }}',
  publishing_confirmation_content: [
    'A published version can be used to enroll patients',
    'All published versions will be visible in the versioning tab',
    'Editing this version will create a new working version which can be published again when ready',
  ],
  query: 'Query',
  query_description: 'Define the operation you want to perform against the GraphQL endpoint.',
  query_edit: 'Edit query',
  query_variables_description:
    'Add variables to your query by providing a JSON-encoded body. You can use Awell variables to insert dynamic data values.',
  question: 'question',
  question_title_placeholder: 'What would you like to ask ?',
  question_copy_options_keyboard_shortcut_tip:
    'You can copy options using keyboard shortcut - "ctrl / ⌘ + shift + c" on focused question',
  range: 'range',
  read_more: 'Read more ...',
  refresh_page: 'Refresh',
  refresh_data_point_definitions: 'Refresh data point list',
  remove: 'Remove',
  remove_step_from_track_error: 'Something went wrong while deleting the step',
  reminder_one: 'Reminder',
  reminder_settings: 'Reminder settings',
  reminder_amount: 'Total reminders',
  reminder_delay: 'Reminder delay',
  reminder_delay_description: 'The amount of time before a reminder is being triggered for an incomplete activity.',
  reminder_amount_description:
    'The total amount of reminders that will be sent for an incomplete activity. If set to 0, no reminders will be sent.',
  reminder_other: 'Reminders',
  reminders_subtitle: 'You can configure reminders to make sure the patient completes open activities.',
  remove_from_library: 'Remove from library',
  rename: 'Rename',
  rename_track: 'Rename track',
  renaming: 'renaming',
  repeat_step: 'Repeat step',
  report_error: 'Report error',
  report_issue: 'Report issue',
  restart: 'Restart',
  retry: 'Retry',
  request: 'Request',
  request_body: 'Request body',
  request_url: 'Request URL',
  response_body: 'Response body',
  response: 'Response',
  case_control_disabled: 'Pause case to enable {{ action }}',
  restart_simulation: 'Restart preview',
  response_time: 'Response time',
  retry_all: 'Retry all',
  retry_all_failed: 'Retry failed',
  role_selection: 'Role selection',
  rule_one: 'Rule',
  rule_has_incomplete_conditions:
    'Some transition rules have incomplete conditions. This will not prevent you from previewing the care flow, however you might end up in a dead end before reaching the end of the care flow.',
  rule_other: 'Rules',
  save: 'Save',
  saved_steps: 'Saved steps',
  saved: 'Saved',
  saving_changes: 'Saving changes...',
  scenario_ended: 'Preview ended',
  scenario_preview_error: 'Something went wrong while loading preview',
  search: 'Search',
  search_activities: 'Search activities by role, name, or activity type',
  search_steps: 'Search steps',
  see_details: 'See details',
  select_amount: 'Amount',
  select_step: 'Select step',
  select_track: 'Select track',
  select_unit: 'Select a unit',
  select: 'Select {{ type }}',
  sendgrid_configuration: 'Sendgrid configuration',
  send_invitation_new_team: 'Create team and send invitations',
  send_invitation_same_team: 'Send invitations',
  send_request: 'Send request',
  settings: 'Settings',
  settings_branding_logo_delete_button_label: 'Remove logo',
  settings_branding_logo_delete_modal_detail: 'The care flow will fall back on the logo defined at the tenant level.',
  set_as_live_version: 'Set as live version',
  share: 'Share',
  show_preview: 'Show preview',
  show_system_activities_label: 'Show Awell activities',
  show_system_activities_tooltip: 'Toggle to show/hide system activities by Awell e.g. Awell activated step Form',
  short_text: 'Short text',
  icd10_classification: 'ICD Classification',
  file_upload: 'File Upload',
  image_upload: 'Image Upload',
  icd_10_classfication_preview_description:
    'Preview mode shows an example list of medical conditions; not all conditions are included.',
  template_step: {
    notify_step_template_remove: 'Step removed from the library',
    notify_step_template_remove_error: 'Something went wrong while removing step from library',
    temporary_step_warning: 'Select action(s) below to create this step',
    create_step: 'Create step',
    discard_step: 'Discard',
    select_action: 'Select action',
    change_action: 'Change action',
  },
  sidetoolbar_actions: {
    cursor: 'Cursor',
    step: 'Step',
    step_tooltip: 'Drag Me',
    note: 'Note',
    cursor_tooltip: 'Click',
  },
  sign_in: 'Sign in',
  single_select: 'Single select',
  slider: 'Slider',
  stakeholder_one: 'Stakeholder',
  search_stakeholders: 'Search Stakeholders',
  source_control: 'Source Control',
  stakeholder_hosted_pages_tooltip: 'Activity Page for {{ stakeholder }}',
  stakeholder_hosted_pages_title: 'Hosted pages',
  stakeholder_create_error: 'Something went wrong while adding a stakeholder',
  stakeholder_exists_warning: 'Person already exists as stakeholder. Please try a different name.',
  stakeholder_label: 'stakeholder name',
  stakeholder_link_description: 'Insert a link that redirects a stakeholder to Hosted Pages to complete activities.',
  stakeholder_other: 'Stakeholders',
  stakeholders_subtitle: 'A list of all stakeholders involved in your care flow',
  standard: 'Standard',
  start_date: 'Start date',
  start_pathway: 'Start care flow',
  start_transition: 'When',
  status: 'Status',
  step_completed: 'When step is completed',
  step_completed_description: 'Tracks that are activated when all the actions from the specified step are completed',
  step_completed_trigger: 'When step {{ step }} in track {{ track }} is completed',
  step_documentation: 'Step documentation',
  step_has_documentation: 'Step has documentation',
  step_never_activated: 'Step never activated',
  step_activated: 'Step activated on {{ date }}',
  step_activities_summary: '{{ completedActivities }} out of {{ totalActivities }} {{ activity }} completed',
  step_load_error: 'Something went wrong while loading the step.',
  step_library: 'Step library',
  step_modal: {
    title: 'Connect {{ name }} to your Awell care flow',
    description:
      'To use this extension, please enable it by clicking the button below. After enabling it in the new tab, return here to continue.',
    confirm: 'I confirm that I`ve enabled this extension correctly',
    redirect_button_text: 'Enable extension',
    confirm_button_text: 'Confirm and close',
  },
  step_template_panel_info: 'Changes will be saved to this step in the library',
  step_title: 'Step title',
  step_one: 'Step',
  step_deleted: '{{ step }} has just been deleted',
  step_library_close: 'Close step library',
  step_library_open: 'Open step library',
  step_other: 'Steps',
  sticky_note: 'Sticky note',
  sticky_note_new: 'New sticky note',
  sticky_note_loading_error: 'Something went wrong while loading the sticky note',
  sticky_note_update_error: 'Something went wrong while updating the sticky note',
  stop_care_flow: 'Stop care flow',
  store_first_match_only: 'Store first match only',
  string: 'text',
  string_value_type: 'String',
  success: 'Success',
  subject: 'subject',
  submit: 'Submit',
  submitted: 'Submitted',
  success_message_invite_users: 'Invitations successfully sent!',
  subscription: 'Subscription',
  switch_to: 'Switch to',
  system_activity_title: 'Technical info',
  system_activity_other: 'System activities',
  tab_title_context: 'Context',
  tab_title_details: 'Details',
  tab_title_form_library: 'My Form Library',
  tab_title_input: 'Input',
  tab_title_meta_data: 'Meta data',
  tab_title_my_pathways: 'My care flows',
  tab_title_results: 'Results',
  tab_title_updates: 'Updates ({{ count }})',
  text: 'text',
  then_go_to: 'Then go to',
  then: 'Then',
  timing: 'Timing',
  timestamp: 'Timestamp',
  time: 'Time',
  time_range: 'Time range',
  time_and_event: 'Time & Event',
  timeline_search_placeholder: 'Search the timeline',
  time_sensitive_condition_helper_text: 'Configure the delay or lag for your track activation.',
  time_and_event_condition_helper_text:
    'Configure the delay timer for your track activation and the start event from which the timer should start.',
  timer_activation_reference_option_ad_hoc: 'When track is manually activated',
  timer_activation_reference_option_pathway_started: 'When care flow is started',
  timer_activation_reference_option_track_started: 'When track is started',
  timer_activation_reference_option_track_completed: 'When track is completed',
  timer_activation_reference_option_step_completed: 'When step is completed',
  timer_activation_reference_option_data_point_collected: 'When data point is collected',
  timer_activation_reference_placeholder: 'Time reference',
  tip_add_to_library:
    "There are no steps in your Library. You can add steps to it by selecting 'Add to Library' on a step in your care flow",
  tip_checklist_add_item: "Type the name of a new task then press 'Enter' to add it to the list.",
  tip_connect_steps:
    'You can also connect two steps straight from the canvas! Click on one of the white dots that appear when you put your mouse over a step, then drag your mouse to the destination step and click to connect them with a new transition.',
  tip_create_new_pathway: "Pick a good name for your new care flow as you won't be able to change once it's created.",
  tip_create_step: "Drag the 'New step' card onto the canvas to add a new step in this track.",
  tip_create_transition: "Click the 'Add transition' button to create a new transition originating from this step.",
  tip_form_who_and_where: 'You can add a new person or location simply by typing a new name.',
  tip_message_who: 'You can add a new person simply by typing a new name.',
  tip_public_simulation: 'Previews help you understand a care flow by exploring different possible scenarios.',
  tip_select_calculation:
    'Not sure which calculation to use? Use our Calculations Explorer to browse through our Calculations Library',
  tip_start_simulation:
    'Previews help you understand a care flow by exploring different possible scenarios. In order to use it, add some actions to at least one step and connect it to Start and End.',
  title: 'title',
  timing_label_immediately: 'Immediately',
  timing_label_delay: 'In {{ amount }} {{unit }}',
  timing_label_reference: 'Immediately after',
  to: 'to',
  to_do: 'to do',
  todo_read_message: 'Waiting for {{ stakeholder }} to read the message.',
  todo_submit_form: 'Waiting for {{ stakeholder }} to submit the form.',
  todo_complete_checklist: 'Waiting for {{ stakeholder }} to complete the checklist.',
  todo_complete_activity: 'Waiting for {{ stakeholder }} to complete the activity.',
  total: 'Total',
  touch_to_select: 'Touch to select a value',
  track: 'Track',
  track_activation: 'Track activation',
  track_completed: 'When track is completed',
  track_completed_description: 'Tracks that are activated when another track completed',
  track_completed_trigger: 'When track {{ track }} is completed',
  track_canvas_add_step_error: 'Something went wrong while adding the step to the care flow',
  track_canvas_add_sticky_note_error: 'Something went wrong while adding the sticky note to the care flow',
  track_canvas_drop_invalid_json_error: 'Something went wrong while adding the item to the care flow',
  track_canvas_move_node_error: 'Something went wrong while moving the node',
  track_canvas_query_error: 'Something went wrong while loading the care flow data',
  track_create: 'Create track',
  track_create_new: 'Create a new track',
  track_create_new_from_intro: "Let's begin",
  track_name: "Track '{{ name }}'",
  track_activation_succeeded: 'Track was activated',
  track_not_activated_conditions_not_satisfied: 'Track was not activated because the conditions were not met',
  track_table_column_name: 'Track name',
  track_table_column_activation: 'Track activation',
  track_title: 'track title',
  tracks_empty_title: 'Welcome to your care flow',
  tracks_not_found: 'No care flow tracks found',
  track_remove_trigger_error: 'Something went wrong while removing the trigger',
  action_deleted_successfully: 'Action deleted successfully',
  delete_this_action: 'Delete this action',
  error_while_deleting_action: 'Something went wrong while deleting the action',
  action_delete_confirmation_title: 'You’re about to delete an action',
  action_delete_confirmation_description:
    'You’re about to delete the “{{ actionTitle }}” action. This change is irreversible. Are you sure you want to proceed?',
  delete_action: 'Delete action',
  track_trigger_other: 'Track triggers',
  track_toolbar: {
    learn_more: 'Learn more about Step Library',
    library: 'Browse step library',
    step_library: 'Step library',
    step_library_description: 'Here’s your library of step templates. Drag them onto the canvas to get started',
    personal: 'Your library',
    personal_title: "It's empty here...",
    personal_description: 'Create steps, save them to your library, and reuse them in all tracks of this care flow.',
    recommended: 'Recommended',
  },
  track_start_label: 'Start',
  track_end_label: 'End',
  true: 'true',
  transformation_one: 'Transformation',
  transformation_other: 'Transformations',
  transformation_description: 'Apply transformations to get full control over the output of your message.',
  transformation_duration_error: 'Please enter at least one duration unit value',
  transformation_parameter_label: 'Parameter',
  transformation_format_label: 'Format',
  transformation_parameter_placeholder: 'Set the parameter...',
  transition_one: 'Transition',
  transition_exists: 'Transition already exists.',
  transition_other: 'Transitions',
  transition_rule_operand: 'Select or add a value',
  transition_rule_operator: 'Select or add an operator',
  transition_rule_reference: 'Select or add a source',
  transitions_incomplete_timing:
    'Some transitions are missing a timing. This will not prevent you from previewing the care flow, however you might end up in a dead end before reaching the end of the care flow.',
  transitions_missing_destination:
    'Some transitions are missing a destination. This will not prevent you from previewing the care flow, however you might end up in a dead end before reaching the end of the care flow.',
  transitions_duplicate:
    'Some transitions are duplicated. This will not prevent you from previewing the care flow, however you might end up in a dead end before reaching the end of the care flow.',
  trash_error: 'Could not move item to trash. Please try again.',
  trash_modal_header: 'Move {{ item }} to trash?',
  trash_modal_primary_button: 'Move to Trash',
  trash_modal_secondary_button: 'Cancel',
  trash_modal_warning_pathway:
    'Your care flow will be marked as archived. Once archived, the care flow will no longer be accessible or editable within Awell Studio and it cannot be started anymore in Orchestration. However, it can be restored by Awell at any point if needed.',
  trash_modal_warning_case: 'This case will be permanently deleted and cannot be restored.',
  trash_modal_warning_scenario: 'This scenario will be permanently deleted and cannot be restored.',
  trash_modal_warning_track: 'This track will be permanently deleted and cannot be restored.',
  trash_success: 'Item moved to trash',
  careflow_triggers: {
    title: 'Care flow triggers',
    column_one_title: 'Set up by you',
    column_two_title: 'Set up by Awell',
    description:
      "Below, you can discover the different trigger types available to start your care flow. Some of these triggers can be set up directly within our apps, while for others, you'll be guided to our documentation for configuration.",
    care_app: 'Awell Care',
    care_app_description: "Start your care flow via Awell's Care app",
    awell_api: 'Awell API',
    awell_api_description: 'Start it programmatically for maximum flexibility',
    incoming_webhooks: 'Incoming Webhooks',
    incoming_webhooks_description: 'Start care flow from events in 3rd party systems',
    hosted_page: 'Hosted Pages Link',
    hosted_pages_description: 'A shareable link that allows patients to self-enroll',
    adt_feed: 'ADT Feeds',
    adt_feed_description: 'Start care flow using ADT messages',
    ftp_connector: 'File Upload',
    ftp_connector_description: 'Start care flow by uploading files to an FTP server',
    coming_soon: 'Coming soon',
    button_disabled: 'This trigger only works when the care flow is published and set as live.',
  },
  trigger_one: 'Trigger',
  trigger_activate: 'Activate trigger',
  trigger_activate_disabled: 'Trigger activation has already been tried',
  trigger_activation_conditions: 'Trigger activation conditions',
  trigger_activation_error: 'Something went wrong while activating trigger',
  trigger_activation_load_error: 'Something went wrong while loading available triggers',
  trigger_api_call_action_error: 'Something went wrong while calling API',
  trigger_description: 'Start a track based on one or multiple triggers.',
  trigger_event: 'Trigger event',
  trigger_other: 'Triggers',
  trigger_count: '{{ count }} scheduled {{ triggerLabel }}',
  trigger_data_point_collected: '{{ name }} data point is collected',
  trigger_data_point_not_collected: 'data point is not collected',
  trigger_extension_webhook_description: 'When a message is received on this endpoint.',
  trigger_pathway_started: 'care flow is started',
  trigger_step_completed: 'step is completed in',
  trigger_track_completed: 'track is completed',
  trigger_track_started: 'track is started',
  trigger_conditions_helper_text:
    'With conditions, this {{ target }} will only be activated if the data from your care flow meets the conditions you have specified.',
  trigger_activate_panel_title: 'Track “{{ track }}” trigger',
  trigger_activated: 'Track was not ',
  trigger_not_activated: 'Track “{{ track }}” will be activated',
  trigger_type: 'Trigger type',
  trigger_type_placeholder: 'Select a trigger type',
  trigger_type_helper_text: 'A trigger is an event that starts your Track.',
  trigger_helper_text: 'A trigger is an event that starts your {{ target }}.',
  trigger_set_settings_error: 'Something went wrong while setting trigger settings',
  trigger_set_type_error: 'Something went wrong while setting trigger type',
  trigger_set_data_point_error: 'Something went wrong while setting trigger data point',
  trigger_set_track_error: 'Something went wrong while setting trigger track',
  trigger_set_step_error: 'Something went wrong while setting trigger step',
  trigger_missing: 'A track is missing a trigger, please contact support to resolve this problem.',
  trigger_missing_pathway_started:
    "None of your tracks have 'Care flow started' trigger, hence there is no way to activate any activities, please use 'Care flow started' as a trigger for one of the tracks.",
  trigger_invalid: 'Some triggers are not fully configured, please check configuration of triggers.',
  trigger_timer_add_error: 'Something went wrong while adding timer',
  trigger_timer_remove_error: 'Something went wrong while removing timer',
  trigger_timer_data_point_definition_error: 'Something went wrong while adding data point definition',
  trigger_timer_step_error: 'Something went wrong while adding timer step',
  trigger_timer_track_error: 'Something went wrong while adding timer track',
  triggered_at: 'triggered at',
  trigger_scheduled_condition: 'Scheduled to be activated when...',
  triggers_not_defined: 'No triggers defined',
  try_again: 'Try again',
  type: 'type',
  unavailable_response: 'Destination system has returned a non-JSON response.',
  undefined: 'Undefined',
  undefined_delay: 'undefined delay',
  undefined_data_point: 'undefined data point',
  undefined_amount: 'amount not defined',
  undefined_unit: 'unit not defined',
  undefined_time_reference: 'undefined time reference',
  undefined_time_conditions: 'Time conditions not defined',
  unit: 'unit',
  unpublished_changes: 'Unpublished changes',
  untitled: 'Untitled',
  untitled_variable: 'Untitled variable',
  update_one: 'update',
  test_configuration: 'Test configuration',
  update_other: 'updates',
  update_link: 'Update link',
  URL: 'URL',
  URL_placeholder: 'Paste the link here',
  use_reference_date: 'Use reference date',
  used_datapoints: 'Used data points',
  used_in_steps: 'Used in steps',
  upload_document: 'Upload a document',
  upload_tooltip: 'Accepted file formats are {{ filetypes }}',
  valid: 'valid',
  validation_extension_not_found: 'Enable extension in settings',
  validation_action_is_invalid: 'Action configuration is invalid',
  value: 'value',
  value_not_selected: 'Value not selected',
  data_point_one: 'Data point',
  data_point_other: 'Data points',
  values: 'values',
  version_other: 'Versions',
  versioning: 'Versioning',
  video: 'video',
  view: 'View',
  view_details: 'View details',
  view_calculation_documentation: 'View documentation',
  view_documentation: 'View documentation',
  view_response: 'View response',
  view_more_details: 'View more details',
  with_specific_conditions: 'with specific conditions',
  webhook_one: 'Webhook',
  webhook_other: 'Webhooks',
  webhook_saved: 'Webhook was successfully saved',
  webhook_subscription_dropdown_placeholder: 'Select an event to subscribe to',
  webhook_saving_error: 'There was an error while saving webhook.',
  webhook_retry_success: "Webhook '{{ name }}' was successfully retried!",
  webhook_description: 'Your app can be notified of real time events happening in the care flow',
  webhook_info_alert_title: 'Webhooks can now be managed at the organization level instead of per care flow',
  webhook_info_alert_link: 'Click here to manage the webhooks on the organization level',
  webhook_empty_message: 'You haven’t created any webhooks yet',
  webhook_empty_response: 'Response is empty',
  webhook_headers: 'Webhook headers',
  webhook_search_placeholder: 'Search by webhook name',
  working_version: 'Working version',
  working_version_subtitle: "This is the version that you're working in but is not yet published",
  working_version_unavailable: 'No published version available',
  webhook_retry_all: 'Retry all',
  webhook_retry_all_failed: 'Retry failed',
  webhook_retry_all_success: 'Successfully retried all webhooks',
  webhook_retry_all_failed_success: 'Successfully retried all failed webhooks',
  webhook_no_subscribed_events: 'You have not subscribed to any event',
  'webhook.form.submitted': 'form.submitted',
  'webhook.activity.created': 'activity.created',
  'webhook.activity.completed': 'activity.completed',
  'webhook.data_point.collected': 'data_point.collected',
  'webhook.reminder.created': 'reminder.created',
  'webhook.pathway.started': 'pathway.started (care_flow.started)',
  'webhook.pathway.completed': 'pathway.completed (care_flow.completed)',
  'webhook.session.started': 'session.started',
  'webhook.session.completed': 'session.completed',
  'webhook.session.expired': 'session.expired',
  WEEK_other: 'weeks',
  WEEK_one: 'week',
  within: 'within',
  YEAR_other: 'years',
  YEAR_one: 'year',
  yes_no: 'Yes {{divider}} No',
  yes: 'yes',
  your_profile_and_settings: 'Your profile and settings',
  careflow_versions: {
    set_live: {
      confirm_modal_title: 'Set version {{ version_number }} as the live version',
      confirm_modal_description_1:
        'You are about to set version {{ version_number }} as the live version of your care flow. This change only impacts Orchestration and doesn’t impact your care flow in Awell Studio.',
      confirm_modal_description_2:
        'Once you have altered the live version, enrollment will happen in the version of the care flow that is the “live” version.',
      confirm_modal_link: 'Learn more about versioning',
      confirm_modal_primary_button: 'Set as live version',
      confirm_modal_secondary_button: 'Cancel',
    },
    restore: {
      confirm_modal_title: 'Restore version {{ version_number }}',
      confirm_modal_description_1: 'You are about to restore version {{ version_number }} inside Awell Studio.',
      confirm_modal_description_2:
        'Your current care flow in Awell Studio will be deleted and replaced with this version. There will be no changes made to your live version in Orchestration.',
      confirm_modal_primary_button: 'Restore',
      confirm_modal_secondary_button: 'Cancel',
    },
  },
  get_json_file: 'Get .json file',
  live_tip: 'This is the current version for all new patient enrollments.',
  draft_tip: 'This is the version that you’re working on.',
  published_tip: 'This version is published but is not enrolling new patients. Set it live to enroll patients.',
  archived_tip: 'This care flow is archived and is not enrolling new patients.',
  careflows_page_search_input: 'Search care flows',
  careflows_page_title: 'My care flows',
  careflows_forms_title: 'My forms',
  careflows_subtitle: 'Create and manage automated care flows to streamline your clinical tasks.',
  data_store_select: {
    placeholder: 'Pick data resource',
  },
  transition_data_store_switcher: {
    label: 'Use ontology based rule editor',
    text: 'Ontology based rules give you access to all data in your account. Please note that ontology based rules are not backwards compatible, so when you switch to this mode all the conditions in this rule will be erased.',
  },
  ontology_mode_switcher: {
    label: 'Use ontology data',
    text: 'Your ontology data includes all the data from all care flows in your account. Enable this mode to use data from other care flows or external systems.',
  },
  duplicate_success: '{{ item }} duplicated successfully',
  duplicate_error: 'Failed to duplicate {{ item }}',
  unit_labels: {
    minute: 'Minute',
    minutes: 'Minutes',
    hour: 'Hour',
    hours: 'Hours',
    day: 'Day',
    days: 'Days',
    week: 'Week',
    weeks: 'Weeks',
    month: 'month',
    months: 'Months',
    year: 'Year',
    years: 'Years',
  },

  descriptionField: 'Description field',
  trigger_editor: {
    event_based: {
      trigger_type_label: 'When an event occurs in another system',
      source_label: 'Where is the data for this event coming from?',
      type_label: 'What type of event would you like to wait for?',
      description_label: 'Are there additional conditions to check before triggering a track?',
      test_configuration_button: 'Test Configuration',
      configuration_tester: {
        title: 'Try it out !',
        description:
          'Interact with {{source}} to generate data. I will execute your instructions and let you know when the provided conditions match.',
        start_test_button: 'Start Test',
        testing_button: 'Testing...',
        confirm_button: 'Confirm Configuration',
        cancel_button: 'Cancel',
        failed: 'Failed',
        passed: 'Passed',
        waiting_for_events: 'Waiting for events...',
        expression_not_set: 'Expression not set',
        show_expression: 'Show expression',
        hide_expression: 'Hide expression',
        expression_set_with_conditions: 'Expression set with {{ conditionsCount }} conditions',
      },
      trigger_description: 'When a {{ type }} event occurs in {{ source }}',
    },
  },
  timer_editor: {
    buttons: {
      save: 'Save',
      clear: 'Clear configuration',
    },
    event_based: {
      tab: 'Until event',
      apply_suggestion: 'Apply suggestion',
      no_actions_message: 'This track does not contain any action that can be used in combination with a timer.',
      suggestions_message:
        'Based on the content of this track, we identified some suggestions for things you may want to wait for.',
      configured_message: 'This timer was automatically configured to: {{ description }}',
    },
    basic_delay: {
      tab: 'Duration',
      unit: 'Select a unit',
      amount: 'Amount',
      wait_for: 'Wait for',
    },
    reference: {
      wait_until: 'Wait until',
      tab: 'Until date',
      adjust_days: 'Adjust by days',
      set_time: 'Select time',
      patient_timezone: {
        label: "Patient's timezone",
        help_text:
          "Automatically considers patient's timezone. If no timezone is set on patient profile, it defaults to UTC.",
      },
      info_title: 'Dates in the past will be rescheduled',
      info_description:
        'If the calculated date is in the past, the timer will be scheduled for the next available occurrence at the specified time.',
      direction: {
        none: 'None',
        before: 'Before',
        after: 'After',
      },
      period: {
        am: 'AM',
        pm: 'PM',
      },
    },
  },
  shelly_disclaimer: 'Please do not share PHI with Shelly',
}

export default translation
