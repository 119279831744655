import { MutationUpdaterFn } from '@apollo/client'
import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import {
  RemoveTriggerFromTrackMutation,
  Trigger,
  useRemoveTriggerFromTrackMutation,
} from './types'

interface UseRemoveTriggerFromTrackProps {
  onSuccess?: () => void
  onError?: () => void
}
interface UseRemoveTriggerFromTrackHook {
  removeTriggerFromTrack: ({
    trackId,
    triggerId,
  }: {
    trackId: string
    triggerId: string
  }) => Promise<void>
}

export const useRemoveTriggerFromTrack = ({
  onSuccess = () => null,
  onError = () => null,
}: UseRemoveTriggerFromTrackProps): UseRemoveTriggerFromTrackHook => {
  const { notifyError } = useNotifications()
 

  const [removeTriggerFromTrackMutation] = useRemoveTriggerFromTrackMutation()

  const removeTriggerFromTrack = async ({
    trackId,
    triggerId,
  }: {
    trackId: string
    triggerId: string
  }) => {
    try {
      const update: MutationUpdaterFn<RemoveTriggerFromTrackMutation> = (
        cache,
        mutationResult,
      ) => {
        if (mutationResult.data?.removeTriggerFromTrack.success) {
          cache.modify({
            id: cache.identify({ __typename: 'Track', id: trackId }),
            fields: {
              triggers(existingTriggerRefs = [], { readField }) {
                return existingTriggerRefs.filter(
                  (triggerRef: Trigger) =>
                    triggerId !== readField('id', triggerRef),
                )
              },
            },
          })
        }
      }
      await removeTriggerFromTrackMutation({
        variables: {
          input: {
            trigger_id: triggerId,
          },
        },
        update,
      })
      onSuccess()
    } catch (error) {
      notifyError({
        message: t('track_remove_trigger_error'),
        error,
      })
    }
    onError()
  }
  return {
    removeTriggerFromTrack,
  }
}
