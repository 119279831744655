import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import { useRemoveTrackFromPathwayMutation } from './types'
import { useRefetchQueriesForDataPoints } from '../useDataPointRefetchQueries'

interface UseRemoveTrackFromPathwayHook {
  removeTrackFromPathway: () => Promise<void>
}

export const useRemoveTrackFromPathway = ({
  pathwayId,
  trackId,
}: {
  pathwayId: string
  trackId: string
}): UseRemoveTrackFromPathwayHook => {
  const { notifyError } = useNotifications()
 

  const refetchQueries = useRefetchQueriesForDataPoints()
  const [removeTrackFromPathwayMutation] = useRemoveTrackFromPathwayMutation()

  const removeTrackFromPathway = async () => {
    try {
      await removeTrackFromPathwayMutation({
        variables: {
          input: {
            pathway_id: pathwayId,
            track_id: trackId,
          },
        },
        refetchQueries,
      })
    } catch (error) {
      notifyError({
        message: t('pathway_remove_track_error'),
        error,
      })
    }
  }
  return {
    removeTrackFromPathway,
  }
}
