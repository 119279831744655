import { useEffect } from 'react'
import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import { useGetPathwayNavigationInfoLazyQuery } from './types'

interface PathwayNameContext {
  loading: boolean
  pathwayName?: string
  pathwayReadonly?: boolean
}
export const usePathwayNavigationInfo = (
  pathwayId?: string,
): PathwayNameContext => {
 
  const { notifyError } = useNotifications()
  const [
    getNavigationInfo,
    { data, error, loading },
  ] = useGetPathwayNavigationInfoLazyQuery()

  useEffect(() => {
    if (pathwayId) {
      getNavigationInfo({
        variables: {
          id: pathwayId,
        },
      })
    }
  }, [pathwayId])

  if (!pathwayId) {
    return {
      loading: false,
      pathwayName: '',
    }
  }

  if (error) {
    notifyError({
      message: t('pathway_fetch_error'),
      error,
    })
    return { loading: false }
  }
  if (loading) {
    return { loading: true }
  }

  const pathwayName = data?.pathway.pathway.title || ''
  const pathwayReadonly = data?.pathway.pathway.readonly || false

  return {
    pathwayName,
    pathwayReadonly,
    loading,
  }
}
