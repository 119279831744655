import { makeStyles } from '@material-ui/core'
import { type, spacing, colors } from '@awell/ui-kit/utils/style-guide'

export const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  headerButton: {
    display: 'flex',
    marginLeft: spacing.xs,
  },
  logo: {
    height: type.m.fontSize,
  },
  pageTitle: {
    ...spacing.paddingX.s,
  },
  iconSpacing: {
    marginRight: spacing.xs,
  },
  iconsUserHelp: {
    paddingLeft: spacing.xxs,
    paddingRight: spacing.xxs,
  },
  status: {
    fontSize: type.xs.fontSize,
    color: colors.neutralDark500,
    marginTop: 3, // keeps text baseline aligned with page title
  },
  breadcrumbs: {
    marginLeft: spacing.xs,
  },
  logoAndBreadcrumbs: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: colors.neutralDark800,
    display: 'flex',
    alignItems: 'center',
  },
  mobile: {},
  mobileMenu: {
    display: 'none',
  },
  previewButton: {
    marginLeft: spacing.xsm,
  },
  '@media (max-width: 700px)': {
    mobile: {
      display: 'none',
    },
    breadcrumbs: {
      display: 'none',
    },
    mobileMenu: {
      display: 'inherit',
    },
    iconSpacing: {
      display: 'none',
    },
    iconsUserHelp: {
      display: 'none',
    },
  },
  sharingDropdown: {
    marginLeft: spacing.xs,
  },
  buttonRotated: {
    transform: 'rotate(180deg)',
  },
  menuButton: {
    color: colors.brand100,
    fontSize: 'inherit',
    boxShadow: '0 0 0 0.0714rem #e6edf9',
    padding: `${spacing.xxs} ${spacing.s}`,
    backgroundColor: colors.brand10,
    display: 'flex',
    alignItems: 'center',
  },
})
