const cases = {
  empty_title: 'Create a case to start previewing',
  empty_description:
    'Preview your care flow and save them as cases. Share cases easily with others for validation or training. Get started with creating your first case!',
  create: 'Create case',
  name: 'Case name',
  table_column_created_by: 'Created by',
  table_column_name: 'Case name',
  description: 'Create cases to preview your care flow. Share cases easily with others for validation or training.',
  preview: 'Preview',
  preview_pathway: 'Preview care flow',
  preview_validation_explanation:
    "Before we start the preview, let's run through the validation checklist to detect if there are any issues in the care flow configuration.",
  preview_validation_title: "Let's check if this care flow is ready to be previewed",
  preview_validation_rerun: 'Run validation again',
  preview_validation_error:
    "If you still see an error and cannot start a preview, use the 'Report error' button below to let us know about your issue. This will automatically send us a report about your issue with all the necessary information so that we can look into it.",
  query_error: 'Something went wrong while fetching the care flow cases',
  create_error: 'Something went wrong while creating the new case',
  start_simulation: 'Start preview',
}

export default cases
