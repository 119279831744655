import { makeStyles } from '@material-ui/core'
import { colors, type, spacing } from '../../utils/style-guide'

export const useStyles = makeStyles({
  title: {
    ...type.xl,
    fontWeight: 'bold',
    color: colors.brand100,
    marginBottom: spacing.m,
  },
  content: {
    ...type.m,
    fontWeight: 'bolder',
    whiteSpace: 'pre-wrap',
    marginBottom: spacing.s,
    textAlign: 'center',
    maxWidth: '500px',
  },
  button: {
    marginBottom: spacing.xxl,
    marginTop: spacing.xl,
  },
  fullScreenContainer: {
    overflow: 'hidden',
    paddingBottom: '100px',
  },
  panelContainer: {
    paddingBottom: 0,
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    '& $title': {
      ...type.m,
    },
    '& $content': {
      ...type.s,
      whiteSpace: 'unset',
    },
    '& $graphic': {
      width: '150%',
    },
  },
})
