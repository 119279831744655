// this is needed while we have the dependency on the ui-kit package in studio
// should remove once it's fully removed
const activityfeed = {
  api_call_sent: 'Shelly sent request to external API',
  api_call_completed: 'Shelly received a successful response from external API',
  api_call_failed: 'Shelly received an error response from external API',
  action_activated: 'Shelly activated action {{ object.name }}',
  action_completed: 'Shelly completed action {{ object.name }}',
  action_discarded: 'Shelly discarded action {{ object.name }}',
  active: 'active',
  activated: 'Activated',
  auditlogs: 'Audit logs',
  last_updated: 'Last updated',
  activated_at: 'Activated on {{ date }}',
  activity_details_hide: 'Hide activity details',
  activity_details_show: 'Show activity details',
  calculation_activity: 'Calculation: {{ calculation_name }}',
  calculation_computed: 'Shelly computed calculation {{ object.name }}',
  calculation_failed: 'Calculation {{ object.name }} failed',
  canceled: 'canceled',
  complete_checklist_button: 'Complete checklist',
  checklist_activity: 'Checklist to complete: {{ object.name }}',
  checklist_submit_active:
    'Shelly is waiting for {{ indirect_object.name }} to complete checklist {{ object.name }}',
  checklist_submitted:
    '{{ subject.name }} completed checklist {{ object.name }}',
  clinical_note_generated: 'Shelly successfully generated the clinical note',
  default_pending_button: 'Complete details',
  done: 'Done',
  emr_metadata: 'EMR metadata',
  emr_processed_success: ['Integration request', 'was processed successfully.'],
  emr_processed_failure: ['Integration request', 'has failed.'],
  emr_report: 'EMR Integration.',
  emr_send_failure: [
    'Shelly was not able to send the request',
    'to the integration provider.',
  ],
  emr_sent: ['Shelly sent the request', 'to the integration provider.'],
  extension_no_data_points: 'No data points are updated by this action.',
  expired: 'expired',
  failed: 'failed',
  completed: 'completed',
  fill_form_button: 'Complete form',
  form_activity: 'Form to complete: {{ object.name }}',
  form_submit_active:
    'Shelly is waiting for {{ indirect_object.name }} to complete the form "{{ object.name }}"',
  form_submitted: '{{ subject.name }} submitted form {{ object.name }}',
  form_expired:
    'Shelly skipped the "{{ object.name }}" form, because it has expired.',
  in_progress: 'In progress',
  message_active:
    'Shelly is waiting for {{ indirect_object.name }} to read the message "{{ object.name }}"',
  message_activity: 'Message to read: {{ object.name }}',
  message_delivered:
    'Message {{ object.name }} was delivered to {{ indirect_object.name }}',
  message_read: '{{ subject.name }} read the message',
  message_sent:
    'Shelly sent message "{{ object.name }}" to {{ indirect_object.name }}',
  message_expired:
    'Shelly skipped the "{{ object.name }}" message, because it has expired.',
  no_activity_updates: 'There are no activity updates yet',
  no_email: 'N/A',
  pathway_activated: 'Shelly activated care flow {{ object.name }}',
  pathway_completed: 'Shelly completed care flow {{ object.name }}',
  pathway_started: '{{ object.name }} started',
  pathway_stopped: 'Shelly stopped the care flow {{ object.name }}',
  pathway_stopped_with_reason:
    'Shelly stopped the care flow with reason: {{ pathwayStatusExplanation }}.',
  pathway_stopped_without_reason:
    'Shelly stopped the care flow without a reason.',
  read_note_button: 'Read note',
  read_message_button: 'Read message',
  reminder_activity_summary:
    'Shelly sent a <strong>reminder</strong> to {{ firstName }} via email {{ email }} because there are incomplete activities.',
  reminder_event:
    'Shelly reminded {{ firstName }} ({{ email }}) for the {{ reminderCount }} time.',
  request: 'request',
  scheduled_elements: 'Scheduled steps and tracks',
  scheduled_elements_summary:
    'These care flow elements are scheduled to be activated in the future',
  step_activated: 'Shelly activated step {{ object.name }}',
  step_completed: 'Shelly completed step {{ object.name }}',
  step_stopped: 'Shelly stopped step {{ object.name }}',
  step_waiting:
    'Shelly is waiting for other paths in the care flow to be processed in order to activate step {{ object.name }}.',
  step_discarded: 'Shelly discarded step {{ object.name }}.',
  stopped: 'stopped',
  tip_role_selection:
    'Select one or more role in the list below to see the activity feed through the eyes of that person.',
  to_do: 'To do',
  track_added_by_api_call:
    'Track {{ object.name }} was added to this care flow via an API call',
  track_added_by_user:
    '{{ subject.id }} added track {{ object.name }} to this care flow',
  track_started: 'Shelly activated track {{object.name}}',
  track_completed: 'Shelly completed track {{ object.name }}',
  track_stopped: 'Shelly stopped track {{ object.name }}',
  upcoming: 'Upcoming',
  upcoming_steps: 'Upcoming steps',
  update_other: 'updates',
  update_one: 'update',
  view_details: 'View details',
  api_call: 'API call',
  form: 'form',
  calculation: 'calculation',
  message: 'message',
  checklist: 'checklist',
  view_activity_button_label: 'View {{ activity }}',
  extension_action_failed: '{{subject.name}} failed to execute the action',
  extension_action_completed:
    '{{subject.name}} successfully executed the action.',
  extension_action_reported:
    '{{subject.name}} reported the following:\n\n{{message}}',
  extension_action_delegated:
    'Shelly delegated {{object.name}} to {{subject.name}} ',
  status: 'Status',
  stakeholder: 'Stakeholder',
  activity_type: 'Activity type',
  select_status: 'Select status',
  select_activity_type: 'Select activity type',
  select_stakeholder: 'Select stakeholder',
  show_scheduled_items: 'Show scheduled items',
  show_system_activities: 'Show system activities',
  no_activities_found: 'No activities found',
  no_activities_to_load: 'No more activities to load',
  today: 'Today',
  yesterday: 'Yesterday',
}

export default activityfeed
