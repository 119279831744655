import React, { cloneElement, type ReactNode } from 'react'
import { toast, type ToastContentProps, Zoom } from 'react-toastify'
import { Icon } from '@awell-health/design-system'
import { t } from 'i18next'
import type {
  Notify,
  NotifyErrorOptions,
  NotifyOptions,
  ErrorWithMessage,
  NotifyError,
} from './types'

import { NotificationTypes } from './types'

interface Notifications {
  notify: Notify
  notifySuccess: Notify
  notifyInformative: Notify
  notifyError: NotifyError
  notifyDelete: Notify
}

const Notification = (props: ToastContentProps<NotifyOptions>): ReactNode => {
  const {
    icon = null,
    message,
    content = null,
    notifyType,
    actions = null,
  } = props.data

  const { closeToast } = props

  let iconComponent: null | ReactNode = null
  switch (notifyType) {
    case NotificationTypes.error:
      iconComponent = (
        <Icon icon='RiErrorWarningLine' className='fill-red-600' />
      )
      break
    case NotificationTypes.success:
      iconComponent = <Icon icon='RiCheckboxLine' className='fill-green-600' />
      break
    case NotificationTypes.warning:
      iconComponent = <Icon icon='RiAlertLine' className='fill-yellow-500' />
      break
    case NotificationTypes.delete:
      iconComponent = <Icon icon='RiDeleteBinFill' className='fill-red-600' />
      break
    default:
      iconComponent = (
        <Icon icon='RiInformationLine' className='fill-blue-300' />
      )
      break
  }

  return (
    <div className='flex gap-2 items-start'>
      <div className=''>
        {icon ?? iconComponent}
      </div>
      <div className='flex flex-col gap-2'>
        <div className='text-slate-800 text-[14px] font-semibold'>{message}</div>
        {content !== null && (
          <div className='text-slate-500 text-sm overflow-auto'>{content}</div>
        )}
        {actions !== null && <div className='flex gap-2 py-2'>{cloneElement(actions, { closeToast })}</div>}
      </div>
    </div>
  )
}

function isErrorWithMessage(error: any): error is ErrorWithMessage {
  return typeof error === 'object' && error !== null && 'message' in error
}

export const useNotifications = (): Notifications => {
  const DEFAULT_TIME_IN_MS_BEFORE_HIDING = 3000

  const notify = (props: NotifyOptions): void => {
    const {
      notifyType = NotificationTypes.informative,
      message,
      icon,
      content,
      actions,
      persist = false,
      closeOnClick = true,
    } = props
    let progressClassName = 'from-blue-200 to-blue-500'

    switch (notifyType) {
      case NotificationTypes.delete:
      case NotificationTypes.error:
        progressClassName = 'from-red-200 to-red-500'
        break
      case NotificationTypes.success:
        progressClassName = 'from-green-200 to-green-500'
        break
      case NotificationTypes.warning:
        progressClassName = 'from-yellow-200 to-yellow-500'
        break
    }

    toast(Notification, {
      data: {
        message,
        icon,
        content,
        actions,
        notifyType,
      },
      hideProgressBar: false,
      position: 'bottom-center',
      closeOnClick,
      autoClose: persist ? false : DEFAULT_TIME_IN_MS_BEFORE_HIDING,
      closeButton: true,
      className: 'bg-white rounded-lg shadow border border-slate-200 w-[400px]',
      transition: Zoom,
      progressClassName: `bg-gradient-to-r h-[3px] ${progressClassName}`,
      icon: false,
    })
  }

  const notifyError = ({ error, content, ...rest }: NotifyErrorOptions): void => {
    if (isErrorWithMessage(error)) {
      notify({
        ...rest,
        notifyType: NotificationTypes.error,
        content: content ?? (
          <div>{t('error_description', { message: error.message })}</div>
        ),
      })
    }
  }

  const notifySuccess = (props: NotifyOptions): void => {
    notify({
      ...props,
      notifyType: NotificationTypes.success,
    })
  }

  const notifyInformative = (props: NotifyOptions): void => {
    notify({
      ...props,
      notifyType: NotificationTypes.informative,
    })
  }

  const notifyDelete = (props: NotifyOptions): void => {
    notify({
      ...props,
      notifyType: NotificationTypes.delete,
    })
  }

  return { notifyError, notify, notifySuccess, notifyInformative, notifyDelete }
}
