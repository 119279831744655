import { t } from 'i18next'
import { useNotifications } from '../useNotifications'
import {
  type GetPathwayCasesQueryVariables,
  type PathwayCaseSummary,
  useGetPathwayCasesQuery,
} from './types'

interface UsePathwayCasesHook {
  pathwayCases: Array<PathwayCaseSummary>
  loading: boolean
}

export const usePathwayCases = ({
  pathwayId,
}: {
  pathwayId: string
}): UsePathwayCasesHook => {
  const { notifyError } = useNotifications()
 

  const variables: GetPathwayCasesQueryVariables = {
    input: { pathway_id: pathwayId },
  }
  const {
    data: getPathwayCasesData,
    error: getPathwayCasesError,
    loading,
  } = useGetPathwayCasesQuery({
    variables,
  })
  if (getPathwayCasesError) {
    notifyError({
      error: getPathwayCasesError,
      message: t('cases:query_error'),
    })
    return { loading: false, pathwayCases: [] }
  }

  const pathwayCases = getPathwayCasesData?.pathway_cases.pathway_cases ?? []

  return { pathwayCases, loading }
}
