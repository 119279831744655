const settings = {
  general_subtitle: 'Manage the general settings of your care flow here',
  dashboard_description:
    'Enter the cumul.io dashboard ID to integrate the dashboard with the care flow (comma separated)',
  pathway_name_label: 'Care flow name',
  pathway_name_description: 'Change the name of your care flow',
  error_notification: 'Something went wrong while loading the care flow settings',
  version_explanation:
    'All changes to care flow settings are automatically saved, but they will only take effect when publishing the care flow. Note that the care flow settings are version independent, so as soon as you publish the updated settings all previous versions of the care flow will use these new settings.',
  update_email_notification_error: 'Something went wrong while updating the notification email settings',
  general_name_error: 'Something went wrong while updating the care flow name',
  reminder_amount_error: 'Something went wrong while updating the care flow reminders amount',
  reminder_delay_error: 'Something went wrong while updating the care flow reminders delay',
  webhooks: {
    name: 'Webhook name',
    name_explanation: 'Give your webhook a name for easy reference',
    test_endpoint_url: 'Test endpoint url',
    test_endpoint_url_explanation: 'A HTTP POST request will be sent to this URL for every event during preview',
    endpoint_url: 'Endpoint url',
    endpoint_url_explanation: 'A HTTP POST request will be sent to this URL for every event during orchestration',
    enabled: 'Enabled',
    enabled_explanation:
      "When enabled, each event's details are delivered to the defined endpoint when that event occurs. If changing this setting, make sure to confirm this change by clicking Save below.",
    subscriptions_title: 'Subscriptions',
    subscriptions_explanation:
      'Subscriptions are types of events that your app can subscribe to. You can subscribe to multiple events for one webhook.',
    headers_title: 'Headers',
    headers_explanation: 'Key-value pairs to be added as headers in all requests.',
    signature_title: 'Signature',
    signature_explanation: 'Public key that can be used to verify the webhook signature (click on the key to copy it).',
  },
  api_call_retry: {
    update_success: 'API call retry settings saved',
    update_error: 'Something went wrong while updating the api call retry settings',
    explanation:
      'These changes will only take effect when publishing the care flow. Note that the care flow settings are version independent, so as soon as you publish the updated settings all previous versions of the care flow will use these new settings.',
    title: 'Retry API calls automatically',
    switch: 'Use specific configuration for this care flow',
    switch_description:
      'Enable this feature if you would like to configure specific behaviour for this care flow. When disabled, your account wide default settings will be used.',
  },
  source_control: {
    title: 'Set up your repository',
    subtitle: 'Source control allows you to track changes and keep a historical record of your care flow’s code.',
    repo_url_label: 'Repository URL',
    branch_name_label: 'Branch',
    access_token_label: 'Access token',
    scm_settings_title: 'Repository Configuration',
    repo_url_description: 'e.g. https://github.com/<org>/<repo>',
    branch_name_description: 'All changes will be directly pushed to this branch',
    access_token_description:
      'Create a personal access token with the following permissions: repo, contents:read, contents:write, metadata:read',
    remove_success: 'Care flow source control settings successfully removed',
    remove_title: 'Remove source control configuration',
    remove_description:
      'Are you sure you want to remove source control integration? This will stop syncing your care-flow with source control.',
    test_title: "You're about to test your source control configuration",
    test_description:
      'To test this configuration, we will push a Readme file to your repository. This will delete other Readme files within the repository. This will not affect your care flow.',
    update_success: 'Source control successfully saved',
    update_error: 'Something went wrong while updating the source control settings',
    test_success: 'Source control was successfully tested on the configured repository.',
    test_error: 'Something went wrong while testing the source control settings',
  },
  extensions: {
    subtitle: 'Use action extensions to further enhance the functionality of your care flow.',
    configuration_load_error: 'Something went wrong while loading extension configurations',
    configuration_successful: 'Extension has been successfully configured',
    configuration_removed_successfully: 'Extension has been successfully removed.',
    load_error: 'Something went wrong while loading extensions',
    refresh: 'Refresh',
    review: 'Review',
    configure: 'Configure',
    refresh_modal_header: 'Refresh {{ item }} extension?',
    refresh_modal_content:
      'Refreshing this extension will automatically update all associated custom action fields, data points, and webhooks.',
    redirect_to_tenant_extensions: 'Click here to manage your extensions for all care flows.',
  },
}

export default settings
