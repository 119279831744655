import { navigate } from '@reach/router'
import { useStytchMemberSession } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import React, { useEffect, useState, type FC, type ReactNode } from 'react'
import { useCookies } from 'react-cookie'
import { environment } from '../environment'
import { useCaptureDev } from './useCaptureDev'
import { useCustify } from './useCustify'
import { useHeap } from './useHeap'

interface AuthenticationGuardProps {
  children: ReactNode
  loadingComponent: ReactNode
}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({
  children,
  loadingComponent,
}) => {
  const [isLoading, setLoading] = useState(true)
  const [cookies, setCookie] = useCookies(['stytch_session', 'auth_next_url'])
  const { session } = useStytchMemberSession()

  // @TODO Check why we use hooks for tools inside the scope of Auth hook
  useHeap()
  useCustify()
  useCaptureDev()

  const redirectToLogin = (): void => {
    setLoading(true)
    const visitedPage = window.location.href
    const expires = new Date()
    expires.setTime(expires.getTime() + 10 * 60 * 1000)
    setCookie('auth_next_url', visitedPage, {
      expires,
      path: '/',
      domain: environment.auth.auth_cookies_allowed_domain,
    })

    void navigate(environment.urls.careops + '/session-expired')
  }

  useEffect(() => {
    // If the guard is loading, the user is either coming from CareOps or we initiated a redirect
    // to the session expired page.
    if (isLoading) {
      // If the session is available, the user was coming from CareOps and the stytch SDK 
      // has successfully created the session
      if (!isNil(session)) {
        setLoading(false)
      }
    } else {
      // If the guard is not loading, then the session changed from available to not available, so
      // it means the SDK expired the session and we need to redirect to the login page.
      if (isNil(session)) {
        redirectToLogin()
      }
    }
  }, [session])

  useEffect(() => {
    // If for some reason the session is still available but the cookie is gone, we should also redirect
    // to the login page as any attempt to call the APIs will fail.
    if (isNil(cookies.stytch_session)) {
      redirectToLogin()
    }
  }, [cookies])

  if (isLoading) {
    return <>{loadingComponent}</>
  }

  return <>{children}</>
}

AuthenticationGuard.displayName = 'AuthenticationGuard'
