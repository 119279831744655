export enum NotificationTypes {
  'warning' = 'warning',
  'error' = 'error',
  'informative' = 'informative',
  'success' = 'success',
  'delete' = 'delete',
}

export interface NotifyOptions {
  message: string
  notifyType?: NotificationTypes
  icon?: JSX.Element
  content?: React.ReactElement | string
  actions?: React.ReactElement | JSX.Element
  persist?: boolean
  closeOnClick?: boolean
}

export type Notify = (props: NotifyOptions) => void

export interface ErrorWithMessage {
  message: string
}

export interface NotifyErrorOptions extends NotifyOptions {
  error?: ErrorWithMessage | unknown
}

export type NotifyError = (props: NotifyErrorOptions) => void
