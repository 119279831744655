import { makeStyles } from '@material-ui/core'
import { useNavigate } from '@reach/router'
import React, { type FC } from 'react'
import classnames from 'classnames'
import { spacing, colors } from '../../utils/style-guide'
import { IconButton } from '../Button'
import { Flex } from '../Flex'
import { ArrowForward, ArrowBack } from '../Icons'
import { Tooltip } from '../Tooltip'

interface IconPosition {
  iconRight?: boolean
}
const useStyles = makeStyles({
  root: {
    '& a, a:link, a:visited, a:hover, a:focus': {
      color: 'inherit',
      textDecoration: 'inherit',
      fontWeight: 'inherit',
      font: 'inherit',
    },
  },
  iconWrapper: {
    marginLeft: ({ iconRight = false }: IconPosition) =>
      iconRight ? spacing.xxs : 0,
    marginRight: ({ iconRight = false }: IconPosition) =>
      !iconRight ? spacing.xxs : 0,
  },
  labelDisabled: {
    color: colors.neutralMid300,
  },
})

export interface NavigationButtonProps extends IconPosition {
  labelContent?: JSX.Element
  linkUrl: string
  disabled?: boolean
  dataTest?: string
}

export const NavigationButton: FC<NavigationButtonProps> = ({
  labelContent,
  iconRight = false,
  linkUrl,
  disabled = false,
  dataTest = '',
}) => {
  const classes = useStyles({ iconRight })
  const navigate = useNavigate()

  const renderIcon = () => (
    <Tooltip info={disabled ? 'Wait, saving changes' : ''}>
      <div className={classes.iconWrapper}>
        <IconButton
          variant='subtle'
          data-test={dataTest}
          disabled={disabled}
          onClick={() => {
            void navigate(linkUrl)
          }}
        >
          {iconRight ? <ArrowForward /> : <ArrowBack />}
        </IconButton>
      </div>
    </Tooltip>
  )

  return (
    <div className={classes.root}>
      <Flex align='center' justify='start'>
        {!iconRight && renderIcon()}
        <span
          className={classnames({
            [classes.labelDisabled]: disabled,
          })}
          style={{ fontSize: '14px' }}
        >
          {labelContent}
        </span>
        {iconRight && renderIcon()}
      </Flex>
    </div>
  )
}

NavigationButton.displayName = 'NavigationButton'
