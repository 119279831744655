import React, { type FC, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

export const NotificationsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useEffect(() => {
    const styleSheet = `:root { --toastify-color-progress-light: white }`
    const styleElement = document.createElement('style')

    styleElement.innerHTML = styleSheet

    document.head.append(styleElement)

    return () => {
      styleElement.remove()
    }
  }, [])

  return (
    <>
      {children}
      <ToastContainer />
    </>
  )
}
