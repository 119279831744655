// this is needed while we have the dependency on the ui-kit package in studio
// should remove once it's fully removed
const enrollment = {
  title: 'Enrollment',
  description: 'How patients are enrolled in the care flow',
  subtitle: 'Active enrollment triggers',
  manage_button: 'Manage Enrollement Triggers',
  status: 'Status',
  trigger: {
    name: 'Name',
    event: 'Event',
    endpoint: 'Endpoint',
    description: 'The care flow will be started based on an incoming webhook from {{ extension_name }}',
    documentation: 'Documentation',
    data_points: 'Data Points',
    data_points_description: 'The data points ingested by this trigger in the care flow',
    data_point_key: 'Data Point Key',
    data_point_definition_id: 'Data Point Definition ID',
  },
  hosted_page: {
    stakeholder: 'Stakeholder',
    language: 'Language',
    shareable_link: 'Shareable Link',
  },
  manual: {
    name: 'Manual enrollment',
    description:
      'Every care flow can be started manually via Awell Care or the API by default. This Enrollment trigger is always enabled and cannot be disabled.',
  },
  careflow_triggers: {
    title: 'Careflows Triggers',
    subtitle: 'Manage your care flows triggers',
    active: 'Active enrollment triggers',
    explore: 'Explore enrollment triggers',
    edit: 'Edit',
    add: {
      webhooks: 'Webhooks',
      webhooks_description:
        'Start a care flow automatically by connecting it to an incoming webhook from an extension.',
      hosted_pages: 'Hosted pages link',
      hosted_pages_description:
        'A shareable link that instantly starts a care flow and directs the user to Hosted Pages. Ideal for onboarding and other flows where users need to take immediate action.',
      bulk_enrollment: 'Bulk enrollment',
      bulk_enrollment_description:
        'In addition to a transactional trigger, you can bulk enroll patients in your care flow. A common example is triggering enrollment via a CSV file upload.',
    },
    add_trigger: 'Add trigger',
    coming_soon: 'Coming soon',
  },
  baseline_data_points: {
    title: 'Baseline data points',
    subtitle: 'Define data points that are expected when starting the care flow',
    create: 'Create',
    create_title: 'Create baseline data point',
    empty: "No baseline data points have been created yet. Please click 'Create' to add one.",
    required: 'Required',
    delete_modal: {
      title: 'Delete baseline data point {{ item }}',
      description: 'Are you sure you want to delete this baseline data point?',
      cancel: 'Cancel',
      delete: 'Delete',
      success: 'Baseline data point {{ item }} deleted successfully',
    },
  },
}

export default enrollment
