const versions = {
    details_panel_published_by: 'Published by {{ user }} on {{ date }}',
    details_comments: 'Publication comments:',
    explainer_title: 'How publishing works',
    explainer_content: [
      'A published version can be used to enroll patients',
      'All published versions will be visible in the versioning tab',
      'Editing this version will create a new working version which can be published again when ready',
    ],
    history: 'Version History',
    history_subtitle: 'Here you see a list of all your published versions',
    table_column_published_by: 'Editor',
    table_column_published_date: 'Last edited',
    table_column_status: 'Status',
    review_changes: 'Review changes',
    history_archived_title: 'This care flow is archived',
    history_archived_subtitle:
      'You can no longer publish this care flow or enroll new patients into this care flow.',
    history_no_action_available: 'No action available',
}

export default versions
