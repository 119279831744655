import { useStytchMember } from '@stytch/react/b2b'
import { Menu as MenuIcon } from '@awell/ui-kit/components/Icons'
import { Menu, MenuItem } from '@awell/ui-kit/components/Menu'
import React, { type FC, useState } from 'react'
import { t } from 'i18next'
import { AppVersion } from '../AppVersion'
import { useStyles } from './useStyles'

interface MobileMenuProps {
  handleLogout: () => void
}

export const MobileMenu: FC<MobileMenuProps> = ({ handleLogout }) => {
 
  const { member } = useStytchMember()
  const classes = useStyles()

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  return (
    <Menu
      open={isMobileMenuOpen}
      onClose={() => {
        setIsMobileMenuOpen(false)
      }}
      onOpen={() => {
        setIsMobileMenuOpen(true)
      }}
      toggleButtonContent={<MenuIcon />}
      dropdownLeft
    >
      <MenuItem disabled>
        {t('logged_in_user_email', { email: member?.email_address })}
      </MenuItem>
      <MenuItem>
        <a
          href={t('link_awell_help')}
          // eslint-disable-next-line react/jsx-no-target-blank
          target='_blank'
          rel='noopener noreferrer'
          className={classes.link}
        >
          {t('menu_awell_help')}
        </a>
      </MenuItem>
      <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
      <MenuItem disabled>
        <AppVersion />
      </MenuItem>
    </Menu>
  )
}
MobileMenu.displayName = 'MobileMenu'
