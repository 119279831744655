import Analytics, { type AnalyticsInstance } from 'analytics'
// @ts-expect-error it's ok no types for this package
import custifyPlugin from '@analytics/custify'
import { useAnalytics } from 'use-analytics'
import { environment } from './environment'

// Export the CustifyEvents enum from the same file as hook
export { CustifyEvents } from './experiments/CustifyEvents'

// Initialize analytics & load plugins
export const setupAnalytics = (app: string = 'Awell'): AnalyticsInstance => {
  const analytics = Analytics({
    app,
    plugins: [
      custifyPlugin({
        accountId: '6582dd6330bbcc84840f3d8b',
      }),
    ],
  })
  return analytics
}

export const useTrackCustify = (): {
  trackCustify: (
    eventName: string,
    metadata?: Record<string, any>,
  ) => Promise<void>
} => {
  const { track } = useAnalytics()

  const trackCustify = async (
    eventName: string,
    metadata: Record<string, any> = {},
  ): Promise<void> => {
    const defaultMetadata = {
      environment: environment.environment,
      ...metadata,
    }
    if (
      environment.environment === 'development' ||
      environment.environment.match(/local.*/) !== null ||
      environment.environment.match(/baker.*/) !== null
    ) {
      console.log('Mock track event', eventName, defaultMetadata)
      return
    }
    void track(eventName, defaultMetadata)
  }

  return { trackCustify }
}
