const form = {
  builder: {
    add_header: 'Pick a question',
    enable_disable_display_conditions_label: 'Visibility logic',
    display_mark_label: 'Display tick marks',
    dropzone_label: 'Drag and drop elements from the sidebar to build your form',
    enable_display_conditions_label: 'Add visibility logic',
    title_placeholder: 'Enter your form title here',
    library_search: 'Search elements',
    library_layout: 'Layout elements',
    clinical_elements: 'Clinical elements',
    library_other: 'Other',
    library_select: 'Select elements',
    library_text: 'Text elements',
    mandatory_error_text: 'This field is required',
    invalid_error_text: 'This answer is in an invalid format',
    mandatory_question_label: 'Answer is required',
    use_select_question_label: 'Use select input for answers',
    max_label_placeholder: 'Max label',
    max_label: 'Max value and label',
    min_label_placeholder: 'Min label',
    min_label: 'Min value and label',
    option_label_placeholder: 'Enter new choice',
    option_value_error_message: 'Only numbers allowed',
    question_with_logic: 'This question is only visible:',
    question_without_logic: 'This question is always visible',
    question_title_placeholder: 'What would you like to ask?',
    recode_values_label: 'Recode answer value(s)',
    restart_preview_label: 'Restart preview',
    select_prev_question: 'Select previous question',
    file_storage_destination: 'File storage destination',
    accepted_file_types: 'Accepted file types',
    select_next_question: 'Select next question',
    show_close_configuration_label: 'Hide options',
    show_min_max_values_label: 'Show min and max values',
    show_open_configuration_label: 'Show options',
    show_question_key_label: 'Change question key',
    step_value_label: 'Step value',
    value_tooltip_on_label: 'Slider value tooltip visible',
    change_title_error: 'Something went wrong while changing the form title',
    change_title_success: 'Form title updated',
    change_key_error: 'Something went wrong while changing the form key',
    change_trademark_error: 'Something went wrong while changing the form trademark',
    change_metadata_error: 'Something went wrong while setting the form metadata',
    change_metadata_success: 'Form metadata saved',
    change_question_order_error:
      'Cannot move the question because, based on its new position, its display logic depends on answers from later questions. Adjust or remove the logic and try again.',
    no_questions: 'Get started by adding a question to your form. You’ll be able to edit the question settings here.',
    select_question: 'Select a question to edit it',
    editor_select_question: 'Select a question on the left to start editing.',
    description_config_hint:
      "This form element doesn't have any settings to set. You can hide or show this element based on previous answers via the Logic tab",
    question_config_error: 'Something went wrong with saving question configuration!',
    question_metadata_hint: 'Add question metadata as JSON. Only static values and constants are supported.',
    change_question_metadata_error: 'Something went wrong while updating the question metadata',
    option_value_type_label: 'Option value type',
    questions_marked_required: 'All questions have successfully been marked as mandatory.',
    questions_marked_optional: 'All questions have successfully been marked as optional.',
    questions_mark_required_tooltip: 'Go to the "Settings" tab if you want to mark all questions as required.\n ',
    change_question_metadata_success: 'Question metadata saved',
    add_question_success: 'Question added successfully',
    add_question_error: 'Failed to add question',
    change_question_order_success: 'Order changed successfully',
    drag_and_drop_elements_from_sidebar: 'Drag and drop elements from the sidebar to build your form',
    select_question_to_edit: 'Select question to edit',
    question_has_visibility_logic: 'This question is shown conditionally.',
    add_option: 'Add option',
    drag_and_drop_placeholder: 'Drag & drop here',
    file_storage_destination_change_helper_text: 'Manage your file storage',
    accepted_file_types_change_helper_text: 'A list of',
    unique_file_type_specifiers: 'unique file type specifiers',
    no_files_uploaded: 'No files uploaded',
  },
  form_one: 'Form',
  form_other: 'forms',
  display_mode: 'Display mode',
  display_mode_description: 'The display mode will determine how your form will be rendered in the Hosted Pages.',
  display_mode_conversational: 'Conversational - Display one question at a time',
  display_mode_regular: 'Regular - Display all questions at once',
  library_title: 'Form Title',
  library_definition_id: 'Definition ID',
  library_key: 'Key',
  library_no_forms_found: 'No forms could be found for your organisation',
  library_questions: 'Questions',

  library_used_in_pathways: 'Care flows',
  library_created_at: 'Created',
  library_last_updated: 'Last updated',
  library_info:
    'This is a list of all forms in your organisation that you have access to. You can edit these forms and use them in your care flows by adding them as actions to any steps. Please note that forms are currently shared across all care flows so any edits to a form will affect all care flows that use it.',
  elements_table_action_plural: 'Actions',
  preview_no_questions:
    'There are no questions to preview yet. In Create mode you can add questions to your form and configure them. You can then find an interactive preview of the form here to test that it works as intended.',
  mandatory_error_with_pagination_info: 'Page {{ page }}: question `{{ questionName }}` is required',
  name: 'Form name',
  submit_error_notification: 'Something went wrong while submitting the form response.',
  success_notification: 'Form submitted successfully.',
  key: 'Form key',
  settings_bulk_operations_label: 'Bulk operations',
  settings_bulk_operations_hint: 'Warning! This will overwrite your existing question requirement configurations.',
  settings_export_form: 'Export form',
  settings_export_form_hint: 'Click here to learn more about exporting your forms.',
  settings_export_help_article:
    'https://help.awellhealth.com/en/articles/6794994-import-and-export-questions-in-and-from-forms',
  settings_bulk_mark_required: 'Mark all questions as required',
  settings_bulk_mark_optional: 'Mark all questions as optional',
  settings_title: 'Form settings',
  settings_key_label: 'Custom form key',
  settings_key_hint:
    'A form key is ideally human-readable, short and contains no spaces. By default, it is generated from the form title',
  key_placeholder: 'yourCustomFormKey',
  settings_trademark_label: 'Trademark',
  settings_trademark_hint: 'This text is displayed at the end of each page of patient-facing forms',
  trademark_placeholder: '© Awell. This form™ is a trademark of Awell',
  settings_metadata_label: 'Metadata',
  settings_metadata_hint: 'Add form metadata as JSON. Only static values and constants are supported',
}

export default form
